import { observable, computed, action, makeObservable } from 'mobx';
import { api } from '../Api/ProofXApi';
import StageCubit, { StageTypes } from './StageCubit';
import { useProofXStore } from '../Store/ProofXStore';
import log from '../ProofX/Logger';
import { CompareModes } from '../CompareModes/CompareModesManager';
import RulerCubit from '../RightPanel/Ruler/RulerCubit';
import EyedropperCubit from '../RightPanel/Eyedropper/EyedropperCubit';
import BarcodeScannerCubit from '../RightPanel/BarcodeScanner/BarcodeScannerCubit';
import { discussionSources } from '../RightPanel/DiscussionPanel/DiscussionPanelCubit';
import AIAssistantCubit from '../RightPanel/AIAssistant/AIAssistantCubit';
import sendGTMEvent from '../GTM';

export const ViewerModes = {
    fileInfo: 'fileInfo',
    annotations: 'annotations',
    projectDiscussion: 'projectDiscussion',
    compare: 'compare',
    ruler: 'ruler',
    eyedropper: 'eyedropper',
    barcodeScanner: 'barcodeScanner',
    aiAssistant: 'aiAssistant',
};

const spellCheckerFileTypes = ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'fodt', 'ods', 'dot', 'htm', 'dotx', 'odt', 'mht', 'ott', 'potx', 'ppsx', 'rtf', 'txt', 'xls', 'pod', 'pps', 'xlsx'];

export default class ViewerCubit {
    // #region Fields

    _error = null;
    _currentMode = null;
    _ruler = null;
    _eyedropper = null;
    _barcodeScanner = null;
    _aiAssistant = null;
    _eventHandlers = {};
    _activeTool = null;
    _loadingAsset = null;
    _longProcessRunning = false;
    _longProcessPrompt = '';
    _backgroundPattern = 'default';

    // #endregion

    constructor() {
        makeObservable(this, {
            _error: observable,
            _currentMode: observable,
            _activeTool: observable.ref,
            _longProcessRunning: observable,
            _longProcessPrompt: observable,
            _backgroundPattern: observable,

            error: computed,
            longProcessRunning: computed,
            longProcessPrompt: computed,
            annotationsCanBeDrawn: computed,
            backgroundPattern: computed,
            annotationsEnabledMode: computed,

            setMode: action,
            setBackgroundPattern: action,
            _setActiveTool: action,
            _setError: action,
            _setLongProcessRunning: action,
        });

        useProofXStore.setState({ viewer: this });
        this._ruler = new RulerCubit();
        this._eyedropper = new EyedropperCubit();
        this._barcodeScanner = new BarcodeScannerCubit();
        this._aiAssistant = new AIAssistantCubit();
    };

    // #region Interface

    get mainAsset() { return this.store.assets.main; }
    get compareAsset() { return this.store.assets.compare; }
    get error() { return this._error; }
    get currentMode() { return this._currentMode; }
    get ruler() { return this._ruler; }
    get eyedropper() { return this._eyedropper; }
    get barcodeScanner() { return this._barcodeScanner; }
    get aiAssistant() { return this._aiAssistant; }
    get longProcessRunning() { return this._longProcessRunning; }
    get longProcessPrompt() { return this._longProcessPrompt; }
    get backgroundPattern() { return this._backgroundPattern; }
    get store() { return useProofXStore.getState(); }
    get annotationsEnabledMode() {
        const { compareModesManager } = this.store;
        return this._currentMode === ViewerModes.annotations ||
            (this._currentMode === ViewerModes.compare &&
                compareModesManager?.activeMode === CompareModes.sideBySide &&
                !compareModesManager?.showDifference
            );
    }

    get annotationsAllowed() {
        return !this.store.proofX.environment.flags.isReadOnly &&
            !this.store.proofX.isTaskCompleted;
    }

    get annotationsCanBeDrawn() {
        return this.annotationsAllowed &&
            this._currentMode === ViewerModes.annotations;
    }

    // called from ThumbnailCubit when user clicks the thumbnail
    // this triggers Stage recreation
    async loadAsset(assetType, asset, page, direction, layerDir) {
        const assetSignature = `${assetType}_${asset.uid}_${page ?? 0}_${layerDir}`;
        if (this._loadingAsset === assetSignature) return;

        log(`➰ loading ${assetType} asset`, asset, page, direction, layerDir);
        this._loadingAsset = assetSignature;

        const { annotationManager } = this.store;
        const currentStage = this.store.stages[assetType];
        const previousAsset = this.store.assets[assetType];

        const pageNum = page ?? 0;
        if (previousAsset &&
            previousAsset.uid === asset.uid &&
            previousAsset.page === pageNum &&
            previousAsset.layerDir === layerDir
        ) return;

        if (annotationManager?.stage &&
            annotationManager.stage === currentStage &&
            previousAsset) {
            annotationManager.reset();
            this._activeTool?.deactivate && this._activeTool.deactivate();
        }

        try {
            if (!asset?.uid || !asset.pages || asset.pages.length === 0) {
                throw new Error('Wrong asset value');
            };
            const size = await this._getImageSize(asset.uid, pageNum);
            if (!size) {
                throw new Error('Error getting asset size');
            };
            const extension = asset.title?.split('.').pop() ?? '';
            const originalExists = await api.checkOriginalAssetFileExists(asset.uid);
            this.store.fileInfo?.setOriginalExists(originalExists?.fileExists ?? false);
            this.store.fileInfo?.setAssetUid(asset.uid);

            const updatedAsset = this.store.proofX.assets.find(a => a.uid === asset.uid);
            this.store.setAsset(assetType, {
                uid: asset.uid,
                page: pageNum,
                thumbnail: updatedAsset.pages[pageNum].thumbnail,
                numberOfComments: updatedAsset.pages[pageNum].numberOfComments,
                formatBasedSize: size,
                appearenceDirection: direction,
                spellcheckerType: extension && spellCheckerFileTypes.includes(extension),
                layerDir,
            });
        } catch (err) {
            console.error(err.message);
        } finally {
            this._loadingAsset = null;
        }
        // after this stage component is reloaded and control is taken by initializeStage function
    }

    // this is an entry point to any stage creation.
    // This function is called when the new Stage component is rendered into the Viewer
    async initializeStage(asset, element, stageType, showControls = true) {
        const currentStage = this.store.stages[stageType];
        log(`🔳 initializing ${stageType} stage`, { asset, element });
        if (currentStage?.container === element) return;

        currentStage?.dispose();
        this.store.setStage(stageType, new StageCubit(element, stageType, showControls));

        const { compareModesManager } = this.store;
        if (compareModesManager?.activeMode && stageType !== StageTypes.middle) {
            await compareModesManager?.loadCompareResult();
        } else {
            await this.updateMainStage();
        }
        this.setMode(this._currentMode ?? ViewerModes.annotations, this._currentMode !== ViewerModes.compare);
        await this._loadFabricLayer();
        this.store.proofX?.setTourReady(true);
    }

    async _loadFabricLayer() {
        const { proofX, annotationManager, stages: { main: mainStage } } = this.store;
        log('💬 initializing annotations');
        annotationManager?.reset();
        mainStage?.createAnnotationLayer();
        await proofX.loadComments(this.mainAsset.uid, this.mainAsset.page);

        if (this._currentMode && this._eventHandlers[this._currentMode]) {
            this._unassignEvents(this._currentMode);
            this._assignEvents(this._currentMode);
        }
    }

    async updateMainStage() {
        const { stages: { main: mainStage } } = this.store;
        if (mainStage) {
            const layerDir = this.mainAsset?.layerDir?.replace('Layers\\', '');
            const layerUrlPart = layerDir ? `${layerDir}/` : '';
            const requestName = layerDir ? 'GetDZILayer' : 'GetDZIFile';
            const url = `/api/${requestName}/${this.mainAsset?.uid}/${this.mainAsset?.page}/${layerUrlPart}dz.dzi/`;
            await mainStage.loadImage(this.mainAsset, url);
        }
    }

    setMode(mode, forceReset = false) {
        this._activeTool?.deactivate && this._activeTool.deactivate();
        const previousMode = this._currentMode;
        if (previousMode === mode && !forceReset) return;
        log(`⛳ Setting Viewer Mode to ${mode}, previous:${this._currentMode}, forceReset=${forceReset}`);

        if (this._currentMode && this._eventHandlers[this._currentMode]) {
            this._unassignEvents(this._currentMode);
        }
        this._currentMode = ViewerModes[mode] ?? null;
        const { annotationManager, compareModesManager, splitView } = this.store;

        if (previousMode === ViewerModes.compare) {
            window.setTimeout(() => {
                compareModesManager.exit();
            }, 500);
        }

        switch (this._currentMode) {
            case ViewerModes.annotations:
                annotationManager.showAnnotations();
                this.store.discussionPanel.toggleSource(discussionSources.asset);
                break;
            case ViewerModes.projectDiscussion:
                annotationManager?.hideAnnotations();
                this.store.discussionPanel.toggleSource(discussionSources.project);
                break;
            case ViewerModes.compare:
                splitView.openRightDrawer();
                annotationManager?.hideAnnotations();
                window.setTimeout(() => {
                    compareModesManager.activateMode(CompareModes.sideBySide);
                    compareModesManager.setShowDifference(false);
                }, 500);
                sendGTMEvent('Compare-Clicked-on-Compare');
                break;
            case ViewerModes.ruler:
                annotationManager?.hideAnnotations();
                this._ruler.activate();
                this._setActiveTool(this._ruler);
                sendGTMEvent('RTUtilities-Ruler');
                break;
            case ViewerModes.eyedropper:
                annotationManager?.hideAnnotations();
                this._eyedropper.activate();
                this._setActiveTool(this._eyedropper);
                sendGTMEvent('RTUtilities-EyeDropper');
                break;
            case ViewerModes.barcodeScanner:
                annotationManager?.hideAnnotations();
                this._barcodeScanner.activate();
                this._setActiveTool(this._barcodeScanner);
                splitView.openRightDrawer();
                sendGTMEvent('RTUtilities-BarcodeReader');
                break;
            case ViewerModes.fileInfo:
                sendGTMEvent('RTUtilities-FileInfo');
                break;
            case ViewerModes.aiAssistant:
                sendGTMEvent('RTUtilities-AIAssistant');
                this._setActiveTool(this._aiAssistant);
                break;
        }
        this._assignEvents(mode);
    }

    isModeActive(mode) {
        return this._currentMode === mode;
    }

    addEvents(modes, events) {
        const handlers = this._eventHandlers;
        if (Array.isArray(modes)) {
            modes.forEach(m => {
                handlers[m] = events;
            });
        } else {
            handlers[modes] = events;
        }
    }

    setBackgroundPattern(pattern) {
        this._backgroundPattern = pattern;
    }

    _setActiveTool(tool) {
        this._activeTool = tool;
    }

    _assignEvents(mode) {
        if (!mode || !this._eventHandlers[mode] || !this.store.fabricLayer) return;
        const layer = this.store.fabricLayer;
        const handlers = this._eventHandlers[mode];
        Object.keys(this._eventHandlers[mode]).forEach(key => {
            layer.on(key, handlers[key]);
        });
    }

    _unassignEvents(mode) {
        if (!mode || !this._eventHandlers[mode] || !this.store.fabricLayer) return;
        const layer = this.store.fabricLayer;
        const handlers = this._eventHandlers[mode];
        Object.keys(this._eventHandlers[mode]).forEach(key => {
            layer.off(key, handlers[key]);
        });
    }

    // #endregion

    // #region Utility methods

    _setError(message) { this._error = message; }

    _setLongProcessRunning(isRunning, prompt) {
        this._longProcessRunning = isRunning;
        this._longProcessPrompt = prompt;
    }

    async _getImageSize(assetUid, pageNum) {
        const consistencyResult = await api.checkAssetConsistency(assetUid, pageNum);
        if (!consistencyResult?.isConsistent) {
            this._setLongProcessRunning(true, this.store.strings.generatingServiceFiles);
            const result = await api.restoreServiceFiles(assetUid);
            if (!result?.success) {
                this.store.proofX.showAlert(this.store.strings.assetFilesCorrupted, 'error');
                return null;
            };
            await this.store.proofX._handleAssetThumbnailsUpdated();
        }
        const size = await api.getAssetPageFormatBasedSize(assetUid, pageNum);
        if (!size || size?.error?.length > 0) {
            this._setError(`Getting image size for asset ${assetUid}, page ${pageNum} is failed`);
            return null;
        }
        this._setLongProcessRunning(false);
        return size;
    }

    // #endregion
}
