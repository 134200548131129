import React, { useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import ModalBase, { Footer as ModalFooter, FooterButton } from './ModalBase';
import { useProofXStore } from '../Store/ProofXStore';
import { FlexRow } from '../../styles';
import { Input as GenericInput } from '../uicontrols/Input';

// #region Styles

const Wrapper = styled.div``;
const Title = styled.div`
    font-size: 18px;
    color: var(--contrast-text);
    font-weight: 500;
    margin: 0;
`;
const Body = styled.div`
    padding: 24px 0px;
    max-width: 600px;
    font-size: 13px;
`;

const Input = styled(GenericInput)`
    min-width: 350px;
    margin-bottom: 8px;
`;

const Form = styled.form.attrs(() => ({
    autoComplete: 'off',
}))`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
`;

const Footer = styled(ModalFooter)`
    justify-content: space-between;    
`;

const FooterRightGroup = styled.div`
    display: flex;
    flex-direction: row;   
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end; 
`;

// #endregion

export default observer(function AddressBookRecordModalModal({ cubit }) {
    const strings = useProofXStore.getState().strings;
    const emailInputRef = useCallback((node) => node?.focus(), []);
    const nameInputRef = useRef(null);

    const handleKeyOnEmail = (e) => {
        if (e.key === 'Enter') {
            nameInputRef.current && nameInputRef.current.focus();
        } else if (e.key === 'Escape') {
            cubit.hide();
        }
    };

    const handleKeyOnName = (e) => {
        if (e.key === 'Enter') {
            cubit.save();
        } else if (e.key === 'Escape') {
            cubit.hide();
        }
    };

    return cubit && (
        <ModalBase cubit={cubit}>
            <Wrapper>
                <Title>{strings.addressBookRecord}</Title>
                <Body>
                    <FlexRow>
                        <Form onSubmit={(e) => cubit.confirmHandler(e)}>
                            <Input ref={emailInputRef}
                                autoComplete="email"
                                placeholder={strings.email}
                                value={cubit.email}
                                onChange={(e) => cubit.setEmail(e.target.value)}
                                onKeyDown={handleKeyOnEmail}
                            />
                            <Input grow
                                ref={nameInputRef}
                                autoComplete="name"
                                placeholder={strings.name}
                                value={cubit.name}
                                onChange={(e) => cubit.setName(e.target.value)}
                                onKeyDown={handleKeyOnName}
                            />
                        </Form>
                    </FlexRow>
                </Body>
                <Footer>
                    <FooterButton danger disabled={!cubit.valid} onClick={() => cubit.removeRecord()}>
                        {strings.remove}
                    </FooterButton>
                    <FooterRightGroup>
                        <FooterButton link disabled={!cubit.valid} onClick={() => cubit.hide()} style={{ marginRight: 8 }}>
                            {strings.cancel}
                        </FooterButton>
                        <FooterButton primary disabled={!cubit.valid} onClick={() => cubit.save()}>
                            {strings.saveAddress}
                        </FooterButton>
                    </FooterRightGroup>
                </Footer>
            </Wrapper>
        </ModalBase>
    );
});
