import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ShareFat, DownloadSimple, Sun, MoonStars, Pencil, IntersectSquare, Question } from '@phosphor-icons/react';
import { useProofXStore } from '../Store/ProofXStore';
import FlippingToggle from '../uicontrols/FlippingToggle';
import Tooltip from '../uicontrols/Tooltip';
import logo from '../../assets/img/appstudio-logo.png';
import ApprovalTools from './ApprovalTools';
import ToggleButton from '../uicontrols/ToggleButton';
import { FlexColumn, FlexRow, smallIconSize } from '../../styles';
import UserAttendance from './UserAttendance';
import { ViewerModes } from '../Viewer/ViewerCubit';
import AppTool from './AppTool';
import sendGTMEvent from '../GTM';
import AnnotationTools from '../Viewer/AnnotationTools';
import CompareTools from '../Viewer/CompareTools';
import { useTour } from '@reactour/tour';

// #region Styles

const StandardLogo = styled.div`
    background-color: var(--popover-background);
    border-radius: 50%;
    margin: 0 var(--sm);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 20px;
        height: 20px;
    }
`;

const ClientLogo = styled.img.attrs(({ img }) => ({
    src: `data:image/png;base64,${img}`,
}))`
    height: 38px;
    max-width: ${({ isMobile }) => isMobile ? '40px' : ''};
    margin: 0 var(--sm);
    border-radius: 18px;
`;

const Grid = styled.div.attrs(() => ({
    className: 'glass-surface',
}))`
    height: 48px;
    display: grid;
    grid-template-columns: [left] 1fr [center] 1fr [right] 1fr [end];
    align-items: center;
    border-radius: 0!important;
    z-index: 1000;
    border-top: none;
`;

const Column = styled(FlexRow)`
    justify-content: ${({ placement }) => placement === 'left' ? 'start' : placement === 'center' ? 'center' : 'end'};
    grid-column: ${({ placement }) => placement};
`;

const ToolbarColumn = styled(FlexColumn)`
    grid-column: center;
    justify-content: center;
`;

const ProjectInfo = styled(FlexRow)`
    margin: 0 var(--md);
`;

const ProjectNamePrompt = styled.div`
    
    font-size: var(--font-size-md);
    margin-right: var(--sm);
    white-space: nowrap;
`;

const ProjectName = styled.div`
    font-family: var(--medium);
    font-size: var(--font-size-lg);
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: text;
`;

const Tool = styled.div`
    margin-left: var(--sm);
`;

const MainTool = styled.div`
    margin: 0 var(--sm);
    display: flex;
`;

// #endregion

export default observer(function AppBar() {
    const proofX = useProofXStore((state) => state.proofX);
    const viewer = useProofXStore((state) => state.viewer);
    const tourCubit = useProofXStore((state) => state.tourCubit);
    const splitView = useProofXStore((state) => state.splitView);
    const fileInfo = useProofXStore((state) => state.fileInfo);
    const annotationManager = useProofXStore((state) => state.annotationManager);
    const strings = useProofXStore((state) => state.strings);
    const isSimpleMode = useProofXStore((state) => state.proofX?.environment?.task?.simpleMode ?? false);
    const { steps, setIsOpen, setSteps, setCurrentStep } = useTour();

    const openTour = useCallback(() => {
        if (!proofX?.isTourReady) return;
        if (!steps) {
            console.log('👌 Tour ready, setting steps');
            setSteps(tourCubit?.steps);
        }
        setCurrentStep(0);
        setIsOpen(true);
        window.localStorage.setItem('tourShown', true);
    }, [setIsOpen, setSteps, steps, tourCubit, proofX?.isTourReady, setCurrentStep]);

    useEffect(() => {
        if (window.localStorage.getItem('tourShown') || !proofX?.isTourReady || !proofX.environment.flags.isExternalReview) return;

        openTour();
    }, [openTour, proofX?.environment, proofX?.isTourReady]);

    if (!splitView) return;
    const projectName = proofX?.environment?.project?.name;
    const hideCompareTools = isSimpleMode || proofX?.isMobile || ((proofX?.assets?.length ?? 0) <= 1 && (proofX?.assets[0]?.pages?.length ?? 0) <= 1);
    const isCompareMode = viewer.currentMode === ViewerModes.compare;

    return (
        <Grid>
            <Column placement='left'>
                <FlexRow>
                    {proofX?.environment?.clientLogo
                        ? <ClientLogo img={proofX?.environment?.clientLogo} isMobile={proofX?.isMobile} />
                        : <StandardLogo><img src={logo} /></StandardLogo>
                    }
                    {projectName && !proofX.isMobile && (
                        <ProjectInfo>
                            <ProjectNamePrompt>{strings.projectName}</ProjectNamePrompt>
                            <ProjectName>{proofX?.environment?.project?.name}</ProjectName>
                        </ProjectInfo>
                    )}
                </FlexRow>
            </Column>

            <Column placement='center'>
                {viewer.annotationsAllowed && (
                    <MainTool>
                        <AppTool filled
                            id='annotation-button'
                            hidden={false}
                            Icon={Pencil} title={strings.annotate}
                            isOn={viewer.currentMode === ViewerModes.annotations && annotationManager.isDrawMode}
                            onPress={() => {
                                sendGTMEvent('Annotatate-Annotation-Clicked-on-Annotate');
                                viewer.setMode(ViewerModes.annotations);
                                if (!isCompareMode) {
                                    if (!annotationManager.visible) {
                                        annotationManager.showAnnotations();
                                        sendGTMEvent('Annotatate-Show-Annotations');
                                    }
                                    const drawMode = isSimpleMode || proofX.isMobile ? 'free' : 'arrow';
                                    annotationManager.toggleDrawMode(annotationManager.isDrawMode ? null : drawMode);
                                }
                            }}
                        />
                    </MainTool>
                )}
                {proofX?.showApprovalTools && !proofX.isApprovalInProgress && (
                    <ApprovalTools />
                )}
                {!proofX?.isMobile && (
                    <MainTool>
                        <AppTool filled
                            id='compare-button'
                            hidden={hideCompareTools}
                            Icon={IntersectSquare} title={strings.compareTools}
                            isOn={isCompareMode}
                            onPress={() => viewer.setMode(isCompareMode ? ViewerModes.annotations : ViewerModes.compare)}
                        />
                    </MainTool>
                )}
            </Column>

            <Column placement='right'>
                {/* TOUR */}
                {proofX?.isTourReady && (
                    <Tool>
                        <Tooltip text={strings.takeTour}>
                            <ToggleButton
                                isOn={false}
                                Icon={<Question size={smallIconSize} weight='fill' />}
                                onClick={openTour}
                            />
                        </Tooltip>
                    </Tool>
                )}
                {/* DARK MODE */}
                {!proofX.isMobile && (
                    <Tool id="dark-mode-button">
                        <Tooltip text={proofX.isDarkMode ? strings.switchToLightMode : strings.switchToDarkMode}>
                            <FlippingToggle
                                isOn={proofX.isDarkMode}
                                OnIcon={<MoonStars size={smallIconSize} weight='fill' />}
                                OffIcon={<Sun size={smallIconSize} weight='fill' />}
                                onClick={() => proofX.toggleTheme()}
                            />
                        </Tooltip>
                    </Tool>
                )}
                {/* SHARE */}
                {proofX?.environment?.flags?.isExternalReview === false && (
                    <Tool id="share-button">
                        <Tooltip text={strings.share}>
                            <ToggleButton
                                isOn={false}
                                Icon={<ShareFat size={smallIconSize} weight='fill' />}
                                onClick={() => proofX.openShareDialog()}
                            />
                        </Tooltip>
                    </Tool>
                )}
                {/* DOWNLOAD */}
                {proofX?.environment?.flags?.canDownloadAssets && fileInfo?.originalExists && (
                    <Tool id="download-button">
                        <Tooltip text={strings.downloadAsset}>
                            <ToggleButton
                                isOn={false}
                                Icon={<DownloadSimple size={smallIconSize} weight='bold' />}
                                onClick={() => proofX.downloadAsset()}
                            />
                        </Tooltip>
                    </Tool>
                )}
                {/* USERS */}
                {!proofX.isMobile && (
                    <Tool>
                        <UserAttendance />
                    </Tool>
                )}
            </Column>

            {!proofX.isMobile && (
                <ToolbarColumn>
                    <AnnotationTools />
                    <CompareTools />
                </ToolbarColumn>
            )}
        </Grid>
    );
});
