/* global $ */

import { observable, computed, action, makeObservable } from 'mobx';
import log, { debug, hubConnecting } from './Logger';
import { api } from '../Api/ProofXApi';

export default class ProofXHub {
    _isOnline = false;
    _hub = null;
    _options = {};

    constructor(options) {
        makeObservable(this, {
            _isOnline: observable,
            _hub: observable.ref,

            hub: computed,

            _initialize: action,
            _setOnline: action,
            _setOffline: action,
        });
        this._options = options;
    }

    get hub() { return this._hub; }

    async _initialize() {
        const { sessionToken, projectUid, userName, isExternal, eventHandlers } = this._options;
        // HomeUrl should be in config only when running in ProofX locally
        const json = await api.getConfigValue('HomeUrl');
        let homeUrl = json?.value ?? '';
        console.log(homeUrl);
        if (!homeUrl) {
            homeUrl = window.location.origin.replace('proofx2.', 'app.') + '/';
        }
        homeUrl = 'https://app.dev.lam.hitech.dev/';
        const me = this;
        console.log(homeUrl);
        await this._loadHubScript(homeUrl);

        this._hub = $.connection.interactiveHub;

        $.connection.hub.url = `${homeUrl}signalr`;
        $.connection.hub.qs = { proofx: `${projectUid}^${isExternal}^${sessionToken}^${userName}` };

        this._subscribeToEvents(eventHandlers);

        $.connection.hub.start().done(function () {
            me._hub.server.initializeByProofX(sessionToken)
                .done(function () {
                    log('🚉 HUB initialized');
                    me._connect();
                });
        }).fail(function (error) {
            error('Invocation of start failed. Error:' + error);
        });

        // Reconnect hub
        $.connection.hub.disconnected(function () {
            log('❌ HUB disconnected');
            me._isOnline = false;
            window.setTimeout(me._connect, 500); // Restart connection after 0.5 seconds.
        });
    }

    _loadHubScript(homeUrl) {
        return new Promise(function (resolve, reject) {
            $.getScript(`${homeUrl}signalr/hubs`, resolve);
        });
    }

    _connect() {
        if (debug && !hubConnecting) return;
        const me = this;
        $.connection.hub.start().done(function () {
            log('🔌 HUB connected');
            me._isOnline = true;
        });
    }

    _subscribeToEvents(events) {
        const hub = this._hub;
        Object.keys(events).forEach(function (eventName) {
            hub?.on(eventName, (...args) => {
                log('🚉 HUB event', eventName, args);
                events[eventName](...args);
            });
        });
    }

    _setOnline() {
        this._isOnline = true;
    }

    _setOffline() {
        this._isOnline = false;
    }
}
