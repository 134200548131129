import { observable, computed, action, makeObservable } from 'mobx';
import { api } from '../../Api/ProofXApi';
import { useProofXStore } from '../../Store/ProofXStore';
import { validateEmail } from '../../Viewer/AnnotationLayer/Utils';

export default class EmailPickerCubit {
    // #region Fields

    _isOpen = false;
    _isFetching = false;
    _allRecords = null;
    _visibleRecords = null;
    _addedRecords = null;
    _inputText = '';
    _selectionIndex = 0;
    _recordModalCubit = null;

    // #endregion

    constructor() {
        makeObservable(this, {
            _isOpen: observable,
            _isFetching: observable,
            _allRecords: observable,
            _visibleRecords: observable,
            _addedRecords: observable,
            _inputText: observable,
            _selectionIndex: observable,

            isOpen: computed,
            emails: computed,
            selectionIndex: computed,
            isFetching: computed,
            visibleRecords: computed,
            addedRecords: computed,
            inputText: computed,

            open: action,
            close: action,
            load: action,
            complete: action,
            setSelectionIndex: action,
            editRecord: action,
            removeRecord: action,
            addEmailsFromInput: action,
            handleChange: action,
            handleInputBlur: action,
            handleKeyDown: action,

            _setAddedRecords: action,
        });
        this.load();
    };

    // #region Interface

    // #region getters

    get isOpen() { return this._isOpen; }
    get inputText() { return this._inputText; }
    get selectionIndex() { return this._selectionIndex; }
    get isFetching() { return this._isFetching; }
    get visibleRecords() { return this._visibleRecords; }
    get addedRecords() { return this._addedRecords; }
    get store() { return useProofXStore.getState(); }
    get recordModalCubit() { return this.store.proofX?.addressBookRecordModal; }
    get isLimitReached() {
        const isOnTrial = this.store.proofX?.environment?.flags?.isClientOnTrial ?? true;
        return this.addedRecords.length >= (isOnTrial ? 1 : 20);
    }

    get emails() {
        return this._addedRecords?.length > 0 && this._inputText === ''
            ? this._addedRecords.map(e => e.email).join(',')
            : '';
    }

    get emailsValid() {
        return this._addedRecords?.length > 0 && this._inputText === ''
            ? this._addedRecords.every(e => validateEmail(e.email))
            : false;
    }

    // #endregion

    // #region actions

    async load() {
        this._isFetching = true;
        const clientUid = this.store.proofX.environment.clientUid;
        const allRecords = await api.getAddressBook(clientUid);
        this._isFetching = false;
        this._allRecords = allRecords;
        this._visibleRecords = allRecords;
        this._setAddedRecords(this._addedRecords?.map(r => allRecords.find(x => x.email === r.email) || r) ?? []);
    }

    open() {
        this._isOpen = true;
    }

    close() {
        this._isOpen = false;
    }

    complete(record) {
        if (this.isOpen && this._visibleRecords.length > 0 && this._visibleRecords[this.selectionIndex]) {
            if (!record) {
                record = this._visibleRecords[this.selectionIndex];
            }
            this._setAddedRecords([...this._addedRecords, record]);
            this.close();
        } else {
            this.addEmailsFromInput();
        }
        this._inputText = '';
        this._visibleRecords = [];
    }

    addEmailsFromInput() {
        const previous = this._addedRecords.map(e => e.email);
        const list = this._inputText.split(/[,; ]/).map(e => e.trim());
        const invalidEntries = [];
        list.forEach(newEmail => {
            if (!this._validateEmail(newEmail)) {
                if (newEmail.length > 0) {
                    invalidEntries.push(newEmail);
                }
                return;
            }
            if (!previous.includes(newEmail)) {
                this._setAddedRecords([...this._addedRecords, { email: newEmail }]);
            }
        });
        this._inputText = invalidEntries.join(', ');
    }

    editRecord(record) {
        this.recordModalCubit?.setOnChangedHandler(this.load.bind(this));
        this.recordModalCubit?.show(record);
    }

    removeRecord(email) {
        this._setAddedRecords(this._addedRecords.filter(r => r.email !== email));
    }

    setSelectionIndex(index) {
        this._selectionIndex = index;
    }

    handleChange(newValue) {
        const token = newValue.toLowerCase();
        const previous = this._addedRecords.map(e => e.email.toLowerCase());

        const filtered = token
            ? this._allRecords.filter(v => {
                const name = v?.name?.toLowerCase() ?? '';
                const email = v?.email.toLowerCase() ?? '';
                return (name.includes(token) || email.includes(token)) && !previous.includes(email);
            })
            : [];
        this._visibleRecords = filtered;
        this._inputText = token;
    }

    handleInputBlur() {
        this.addEmailsFromInput();
        setTimeout(() => this.close(), 200);
    }

    handleKeyDown(e) {
        this.open();
        switch (e.keyCode) {
            case 9: // tab
            case 13: // enter
            case 32: // space
            case 186: // comma
            case 188: // parenthesis
                this.complete();
                this._haltEvent(e);
                break;
            case 8: // backspace
                if (this._inputText.length === 0) {
                    this._setAddedRecords(this._addedRecords.slice(0, -1));
                }
                break;
            case 27: // esc
                this.close();
                break;
            case 38: // up arrow
                this._selectionIndex = this._selectionIndex > 0 ? this._selectionIndex - 1 : this._visibleRecords.length - 1;
                this._haltEvent(e);
                break;
            case 40: // down arrow
                this._selectionIndex = this._selectionIndex < this._visibleRecords.length - 1 ? this._selectionIndex + 1 : 0;
                this._haltEvent(e);
                break;
        }
    };

    // #endregion

    // #endregion Interface

    // #region private methods

    _setAddedRecords(records) {
        console.log('🌻', records);
        this._addedRecords = records;
    }

    _validateEmail(email) {
        if (!email) return false;
        const emailRegex = /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])([;,])?)+$/i;
        return emailRegex.test(email.toLowerCase());
    }

    _haltEvent(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    // #endregion
}
