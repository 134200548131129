/* global OpenSeadragon */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { observable, computed, action, makeObservable } from 'mobx';
import { useProofXStore } from '../../../Store/ProofXStore';
import TextBox from './TextBox';
import { styles } from '../FigureCubit';
import EditorCubit from '../../../uicontrols/CommentEditor/EditorCubit';
import DropdownMenuCubit from '../../../uicontrols/DropdownMenuCubit';
import log from '../../../ProofX/Logger';
import { ViewerModes } from '../../ViewerCubit';
import { api } from '../../../Api/ProofXApi';
import sendGTMEvent from '../../../GTM';

export default class TextBoxCubit {
    _visible = true;
    _state = 'hidden';
    _position = { x: 0, y: 0 };
    _size = { width: 0, height: 0 };
    _annotationCubit = null;
    _editorCubit = null;
    _anchorElement = null;
    _osdViewer = null;
    _expanded = true;
    _editMode = false;
    _mouseTracker = null;
    _menuCubit = null;
    _figuresSubmenuOpen = false;
    _highlighted = false;
    _dragProps = {
        element: null,
        startImgPoint: null,
        startClientPoint: null,
        dirty: false,
    };

    _resizeProps = {
        element: null,
        startWidth: null,
        startResizerX: null,
    };

    _eventsMap = {
        desktop: {
            mousedown: ['mousedown'],
            mouseup: ['mouseup'],
            mousemove: ['mousemove'],
        },
        mobile: {
            mousedown: ['touchstart'],
            mouseup: ['touchend', 'touchcancel'],
            mousemove: ['touchmove'],
        },
    };

    constructor(annotationCubit) {
        makeObservable(this, {
            _state: observable,
            _anchorElement: observable.ref,
            _position: observable,
            _size: observable,
            _editMode: observable,
            _annotationCubit: observable.ref,
            _editorCubit: observable.ref,
            _osdViewer: observable.ref,
            _visible: observable,
            _expanded: observable,
            _dragProps: observable.ref,
            _resizeProps: observable.ref,
            _menuCubit: observable.ref,
            _highlighted: observable,

            annotationCubit: computed,
            userName: computed,
            isExternalUser: computed,
            editorCubit: computed,
            editMode: computed,
            id: computed,
            size: computed,
            position: computed,
            date: computed,
            dragging: computed,
            resizing: computed,
            isVisible: computed,
            expanded: computed,
            visible: computed,
            highlighted: computed,
            state: computed,
            textFrameState: computed,
            editMenu: computed,
            reactions: computed,

            _anchorId: computed,

            mount: action,
            toggleVisible: action,
            toggleExpanded: action,
            toggleHighlight: action,
            toggleEditMode: action,
            cancelEdit: action,
            hover: action,
            startDragging: action,
            startResizing: action,
            dispose: action,
            toggleEditMenu: action,
            changeColor: action,
            updateElementId: action,
            toggleReaction: action,
            _drag: action,
            _stopDragging: action,
            _resize: action,
            _stopResizing: action,

            _setAnnotationCubit: action,
            _setState: action,
            _setAnchorElement: action,
        });
        this._setAnnotationCubit(annotationCubit);
        this._menuCubit = new DropdownMenuCubit(this);
        this._visible = this.store.annotationManager?.visible ?? false;
    }

    get annotationCubit() { return this._annotationCubit; }
    get id() { return this._annotationCubit.id ?? this._annotationCubit._tempUid; }
    get isNew() { return !!this._annotationCubit._tempUid; }
    get commentUid() { return this._annotationCubit.annotation.commentUid; }
    get isTextBox() { return true; }
    get userUid() { return this._annotationCubit.annotation.userUid; }
    get userName() { return this._annotationCubit.annotation.userName ?? this.store.proofX.userName; }
    get isExternalUser() { return this._annotationCubit?.isExternalUser; }
    get text() { return this._annotationCubit?.text ?? ''; }
    get size() { return this._size; }
    get color() { return this._annotationCubit?.color; }
    get colorVariants() { return this._annotationCubit?.colorVariants; }
    get position() { return this._position; }
    get isVisible() { return this._state !== 'hidden'; }
    get visible() { return this._visible; }
    get expanded() { return this._expanded; }
    get highlighted() { return this._highlighted; }
    get editMode() { return this._editMode; }
    get editable() { return !this.store.proofX.isTaskCompleted && (this._annotationCubit?.editable ?? false); }
    get deletable() { return !this.store.proofX.isTaskCompleted && (this._annotationCubit?.deletable ?? false); }
    get commentComplete() { return this._annotationCubit.annotation.isComplete; }
    get commentInternal() { return this._annotationCubit.annotation.isInternal; }
    get editorCubit() { return this._editorCubit; }
    get state() { return this._state; }
    get dragging() { return !!this._dragProps?.element; }
    get resizing() { return !!this._resizeProps?.element; }
    get taggedUsers() { return []; }
    get editMenu() { return this._menuCubit; }
    get figuresSubmenuOpen() { return this._figuresSubmenuOpen; }
    get hasActiveFigureToDelete() { return this._annotationCubit.hasActiveFigureToDelete; }
    get stage() { return this.store.annotationManager.stage; }
    get store() { return useProofXStore.getState(); }
    get date() { return this._annotationCubit?.date ?? ''; }
    get reactions() { return this._annotationCubit?.reactions ?? []; }

    get textFrameState() {
        return this._expanded
            ? (this._state === 'hovered' ? 'hovered' : 'expanded')
            : 'collapsed';
    }

    get _anchorId() { return `textBox-${this.id}`; }

    mount(position, size) {
        log('🗨 Mounting textbox', position, size);
        if (!this.stage) return;

        this._setOSDViewer(this.stage.osdViewer);

        const anchor = document.createElement('div');
        anchor.setAttribute('id', this._anchorId);
        anchor.className = 'textbox';
        this._setAnchorElement(anchor);
        this.stage.osdViewer.addOverlay({
            element: anchor,
            location: new OpenSeadragon.Point(0, 0),
        });
        const root = createRoot(anchor);
        root.render(<TextBox cubit={this} />);
        this.updatePosition(position, size ?? styles.textBoxSize);
        this._setState('visible');

        this._mouseTracker = new OpenSeadragon.MouseTracker({
            userData: `${this._anchorId}.Tracker`,
            element: this._anchorId,
            preProcessEventHandler: function (eventInfo) {
                switch (eventInfo.eventType) {
                    case 'pointerdown':
                    case 'pointerup':
                    case 'click':
                    case 'pointerover':
                    case 'pointerout':
                        eventInfo.stopPropagation = true;
                        eventInfo.preventDefault = false;
                        eventInfo.preventGesture = true;
                        break;
                    case 'contextmenu':
                        // allow context menu to pop up
                        eventInfo.stopPropagation = true;
                        eventInfo.preventDefault = true;
                        break;
                    default:
                        break;
                }
            },

            overHandler: function (e) {
                // $('#overlay1').toggleClass('selected');
            },
        });
    }

    animateTap() {
        this._setState('tapped');
    }

    toggleExpanded(expanded) {
        const newValue = expanded ?? !this._expanded;
        this._expanded = newValue;
    }

    toggleVisible(visible) {
        const newValue = visible ?? !this._visible;
        this._visible = newValue;
    }

    toggleHighlight(isOn) {
        this._highlighted = isOn;
        if (isOn) {
            this._centerInViewport();
        }
    }

    toggleEditMode(isOn) {
        if (!this._editorCubit) {
            this._editorCubit = new EditorCubit(this.id, this.doneEditing.bind(this), this.cancelEdit.bind(this));
        }
        this._editMode = isOn;
        const { annotationManager } = this.store;
        this.stage.toggleMouseTracker(!isOn);

        if (isOn) {
            this._editorCubit.initWithHtml(this.text);
            this._anchorElement.style.zIndex = 100010;
            if (this.store.proofX.isMobile) {
                this._centerInViewport();
            }
            if (this.text) {
                window.setTimeout(() => {
                    this.editorCubit.selectAll();
                }, 200);
            }
            annotationManager.setActiveAnnotation(this._annotationCubit);
        } else {
            if (this._editorCubit.text.trim() !== '') {
                this._annotationCubit.updateText(this._editorCubit.html);
            } else if (this.isNew) {
                this._annotationCubit.dispose();
            }
            this._anchorElement.style.zIndex = '';
            // annotationManager.toggleDrawMode(false);
            annotationManager.setActiveAnnotation(null);
        }
    }

    toggleReaction(emoji) {
        const currentReactions = this.reactions;
        const proofX = this.store.proofX;
        const userReaction = currentReactions.find(r => r.ExecutorName === proofX.userName);
        let updatedReactions = [...currentReactions];
        if (userReaction) {
            if (userReaction.Emoji === emoji) {
                updatedReactions = currentReactions.filter(r => r.ExecutorName !== proofX.userName);
            } else {
                userReaction.Emoji = emoji;
            }
        } else {
            updatedReactions.push({ UserUid: proofX.environment.userUid, ExecutorName: proofX.userName, Emoji: emoji });
        }
        this.annotationCubit.updateReactionsJson(JSON.stringify(updatedReactions));
        api.toggleReaction(this.annotationCubit.annotation.commentUid, emoji);
        sendGTMEvent('Reactions-Reaction-Toggled');
    }

    doneEditing() {
        this.toggleEditMode(false);
    }

    cancelEdit() {
        this._editMode = false;
        this.stage.toggleMouseTracker(true);
        this.store.annotationManager.setActiveAnnotation(null);
        if (this.isNew) {
            this._annotationCubit.dispose();
        }
    }

    hover(hovered, shouldPropagate) {
        if (this.editMode) return;
        this._setState(hovered ? 'hovered' : 'visible');
        if (shouldPropagate) {
            this._annotationCubit.hover(hovered, this);
        }
    }

    // #region Moving

    startDragging(element, startPoint) {
        if (this.editMode) return;
        if (this.store.proofX.isMobile) {
            this.stage.toggleMouseTracker(false);
        };
        const mouseMoveHandler = this._drag.bind(this);
        const mouseUpHandler = this._stopDragging.bind(this);
        this._putOnTop(element);

        this._dragProps = {
            element,
            mouseMoveHandler,
            mouseUpHandler,
            startImgPoint: this.position,
            startClientPoint: startPoint,
            positionChanged: false,
        };

        this._addListener('mousemove', mouseMoveHandler);
        this._addListener('mouseup', mouseUpHandler);
    }

    _drag(e) {
        const point = { x: e.pageX, y: e.pageY };
        if (!this.dragging) return;
        const { element, startClientPoint, startImgPoint } = this._dragProps;
        const delta = {
            x: point.x - startClientPoint.x,
            y: point.y - startClientPoint.y,
        };
        if (delta.x + delta.y > 0) {
            this._dragProps.positionChanged = true;
        }
        element.style.left = delta.x + 'px';
        element.style.top = delta.y + 'px';

        if (!startImgPoint || !this._osdViewer?.viewport) return;

        const viewport = this._osdViewer.viewport;
        const startImg = new OpenSeadragon.Point(startImgPoint.x, startImgPoint.y);
        const startViewer = viewport.imageToViewerElementCoordinates(startImg);
        const currentViewer = new OpenSeadragon.Point(startViewer.x + delta.x, startViewer.y + delta.y);
        const currentImg = viewport.viewerElementToImageCoordinates(currentViewer);
        this._setPosition(currentImg);
        this._annotationCubit.updateConnectingLines();
    }

    _stopDragging() {
        const { mouseMoveHandler, mouseUpHandler } = this._dragProps;
        this._removeListener('mousemove', mouseMoveHandler);
        this._removeListener('mouseup', mouseUpHandler);

        this.updatePosition(this._position);
        if (this.editable && !this.isNew && this._dragProps.positionChanged) {
            this._annotationCubit.save(true);
        }
        if (this.store.proofX.isMobile) {
            this.stage.toggleMouseTracker(true);
        };

        this._dragProps = {
            element: null,
            startImgPoint: null,
            startClientPoint: null,
            dirty: true,
        };
    }

    _addListener(eventName, handler) {
        const device = this.store.proofX.isMobile ? 'mobile' : 'desktop';
        const events = this._eventsMap[device][eventName];
        events.forEach(event => {
            document.addEventListener(event, handler);
        });
    }

    _removeListener(eventName, handler) {
        const device = this.store.proofX.isMobile ? 'mobile' : 'desktop';
        const events = this._eventsMap[device][eventName];
        events.forEach(event => {
            document.removeEventListener(event, handler);
        });
    }

    // #endregion

    startResizing(element, startX) {
        const mouseMoveHandler = this._resize.bind(this);
        const mouseUpHandler = this._stopResizing.bind(this);
        this._putOnTop(element);

        this._resizeProps = {
            element,
            mouseMoveHandler,
            mouseUpHandler,
            startWidth: element.clientWidth,
            startResizerX: startX,
        };

        this._addListener('mousemove', mouseMoveHandler);
        this._addListener('mouseup', mouseUpHandler);
    }

    updatePosition(position, size, fromOutside) {
        if (fromOutside && (this.dragging || this.resizing)) return;
        if (fromOutside && this._dragProps.dirty && (position.x !== this.position.x || position.y !== this.position.y)) {
            // this happens when the textbox was dragged locally before we recieved updated position.
            this._annotationCubit.save(true);
            this._dragProps.dirty = false;
            return;
        }

        const osd = this._osdViewer;
        this._anchorElement.setAttribute('id', this._anchorId);
        const frame = osd?.getOverlayById(this._anchorId);
        if (!osd || !frame) return;
        this._setPosition(position ?? osd.viewport.viewportToImageCoordinates(frame.location));
        size && this._setSize(size);
        const pos = osd.viewport.imageToViewportCoordinates(this._position.x, this._position.y);
        if (this._dragProps.element) {
            this._dragProps.element.style.left = 0;
            this._dragProps.element.style.top = 0;
        }
        osd.updateOverlay(this._anchorId, pos, OpenSeadragon.Placement.TOP_LEFT);
        this._annotationCubit.updateConnectingLines();
    }

    dispose() {
        this._osdViewer.removeOverlay(this._anchorId);
    }

    toggleEditMenu() {
        this.editMenu.toggle();
    }

    addFigure(figureType) {
        const { annotationManager } = this.store;
        annotationManager.setActiveAnnotation(this._annotationCubit);
        annotationManager.toggleDrawMode(figureType);
    }

    removeSelectedFigure() {
        this._annotationCubit.removeSelectedFigure();
    }

    changeColor(color) {
        this._annotationCubit.changeColor(color);
    }

    updateElementId(id) {
        this._anchorElement.setAttribute('id', id);
    }

    deleteAnnotation() {
        const strings = this.store.strings;
        const proofX = this.store.proofX;
        proofX.showConfirm(
            strings.confirmDeletingAnnotation,
            strings.deletingAnnotationPrompt,
            strings.delete,
            () => this._annotationCubit.delete(),
        );
    }

    revealInDiscussionPanel() {
        this.store.viewer.setMode(ViewerModes.annotations);
        this.store.discussionPanel.highlightComment(this.commentUid);
        this.store.splitView.openRightDrawer();
    }

    _setState(newState) { this._state = newState; }
    _setSize(size) { this._size = size; }
    _setAnchorElement(elem) { this._anchorElement = elem; }
    _setAnnotationCubit(cubit) { this._annotationCubit = cubit; }
    _setOSDViewer(osd) { this._osdViewer = osd; }
    _setPosition(newPos) {
        const oldPos = this._position;
        if (!newPos || !oldPos || (newPos.x === oldPos.x && newPos.y === oldPos.y)) return;
        this._position = newPos;
    }

    _resize(e) {
        const x = e.clientX;
        if (!this.resizing) return;
        const { element, startWidth, startResizerX } = this._resizeProps;
        const delta = x - startResizerX;
        element.style.width = Math.max(startWidth + delta, styles.textBoxSize.width) + 'px';

        this._setSize({ width: element.clientWidth, height: element.clientHeight });
    }

    _stopResizing() {
        const { mouseMoveHandler, mouseUpHandler } = this._resizeProps;
        this._removeListener('mousemove', mouseMoveHandler);
        this._removeListener('mouseup', mouseUpHandler);

        this.updatePosition(this._position, this._size);
        this._annotationCubit.save(true);

        this._resizeProps = {
            element: null,
            startWidth: null,
            startResizerX: null,
        };
    }

    _putOnTop(element) {
        this.store.annotationManager?.allTextBoxes.forEach(el => {
            el.style.zIndex = '';
        });
        element.closest('.textbox').style.zIndex = 100;
    }

    _centerInViewport() {
        const viewport = this.stage?.osdViewer?.viewport;
        const { x, y } = this.position;
        viewport?.panTo(viewport.imageToViewportCoordinates(x, y));
    }
};
