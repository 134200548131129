import { observable, computed, action, makeObservable } from 'mobx';
import convert from 'color-convert';
import FigureFactory from './FigureFactory';
import { useProofXStore } from '../../Store/ProofXStore';
import { api } from '../../Api/ProofXApi';
import TextBoxCubit from './TextBox/TextBoxCubit';
import { generateTempUid, getUnrotatedPoint } from './Utils';
import { getLocaleCode } from '../../../localization/strings';
import sendGTMEvent from '../../GTM';

export const annotationColors = {
    red: '#F44336',
    orange: '#FF9800',
    yellow: '#FFEB3B',
    green: '#43A047',
    blue: '#2196F3',
    purple: '#9C27B0',
};

const legacyColors = {
    green: { mainColor: '#43A047', hoverColor: '#4ebb52', glowColor: '#185d1b' },
    purple: { mainColor: '#9C27B0', hoverColor: '#cc32e6', glowColor: '#671775' },
    blue: { mainColor: '#2196F3', hoverColor: '#5fb8ff', glowColor: '#0e5690' },
    yellow: { mainColor: '#FFEB3B', hoverColor: '#fff6a6', glowColor: '#a5961a' },
    orange: { mainColor: '#FF9800', hoverColor: '#ffb850', glowColor: '#7d4b00' },
    red: { mainColor: '#F44336', hoverColor: '#ff6055', glowColor: '#9c251c' },
};

export default class AnnotationCubit {
    _annotation = null;
    _figures = [];
    _layer = null;
    _tempUid = null; // used for initial save request
    _uid = null;
    _id = null;
    _text = '';
    _editable = false;
    _textBoxCubit = null;
    _color = annotationColors.blue;
    _colorVariants = { default: annotationColors.blue };
    _figureBeingDrawn = null;

    constructor(annotation) {
        makeObservable(this, {
            _annotation: observable.ref,
            _figures: observable,
            _layer: observable.ref,
            _id: observable,
            _tempUid: observable,
            _text: observable,
            _editable: observable,
            _textBoxCubit: observable.ref,
            _color: observable,
            _colorVariants: observable.ref,

            id: computed,
            tempUid: computed,
            text: computed,
            color: computed,
            colorVariants: computed,
            date: computed,
            annotation: computed,
            editable: computed,
            deletable: computed,
            expanded: computed,
            editMode: computed,
            hasActiveFigureToDelete: computed,
            reactions: computed,

            update: action,
            expand: action,
            collapse: action,
            hover: action,
            updateZoom: action,
            updateConnectingLines: action,
            updateText: action,
            changeColor: action,
            removeSelectedFigure: action,
            dispose: action,
            highlight: action,
            dropHighlight: action,
            updateReactionsJson: action,

            _init: action,
            _setAnnotation: action,
            _createOrUpdateTextBox: action,
            _initializeTextBox: action,
        });
        if (!annotation.isNew) {
            this._setAnnotation(annotation);
        } else {
            this._init(annotation);
        }
        this._layer = this.store.fabricLayer;
    }

    get id() { return this._id; }
    get uid() { return this._uid; }
    get tempUid() { return this._tempUid; }
    get text() { return this._text; }
    get color() { return this._color; }
    get colorVariants() { return this._colorVariants; }
    get date() { return this._date; }
    get annotation() { return this._annotation; }
    get editMode() { return this._textBoxCubit?.editMode ?? false; }
    get expanded() { return this._textBoxCubit?.expanded ?? false; }
    get _mainStage() { return this.store.stages.main; }
    get store() { return useProofXStore.getState(); }
    get reactions() { return this._annotation?.reactionsJson ? JSON.parse(this._annotation.reactionsJson) : []; }
    get isExternalUser() {
        const env = this.store.proofX.environment;
        return this._annotation.userName ? !!this._annotation.userName.match(/[^(]+\(by[^)]+\)/g) : env.flags.isExternalReview;
    }

    get hasActiveFigureToDelete() {
        return this._figures.length > 1 &&
            this.store.annotationManager.selectedFigureCubit?._annotationCubit === this;
    }

    get editable() {
        const { environment } = this.store.proofX;
        return this._annotation.userUid === environment.userUid && this._annotation.userName === this.store.proofX.userName;
    }

    get deletable() {
        const hasReplies = this.store.assetComments.filter(c => c.parentCommentUid === this.uid).length > 0;
        return this.editable && !hasReplies;
    }

    get _newFigureId() {
        let greatestId = 0;
        this._figures.forEach(figure => {
            greatestId = Math.max(figure.id, greatestId);
        });
        return greatestId + 1;
    }

    // #region drawing figures

    startDraw(figureType, pointer) {
        const figure = FigureFactory.create(figureType, this._newFigureId, this);
        figure.startDraw(pointer);
        this._figureBeingDrawn = figure;
    }

    continueDrawing(pointer) {
        this._figureBeingDrawn?.handleDrawing(pointer);
    }

    endDrawing(pointer) {
        this._figureBeingDrawn?.handleEndDrawing(pointer);
    }

    finalizeFigure(figureCubit) {
        if (!figureCubit) return;
        this._figures.push(figureCubit);
        figureCubit.setNew(true);
        figureCubit._figure.annotationCubit = this;
        figureCubit._figure.figureCubit = figureCubit;
        this._figureBeingDrawn = null;

        if (!this._textBoxCubit) {
            this._initializeTextBox(figureCubit);
        } else {
            this.save();
            figureCubit.updateConnectingLine();
            this._mainStage.toggleMouseTracker(true);
            const { annotationManager } = this.store;
            // annotationManager.toggleDrawMode(false);
            annotationManager.setActiveAnnotation(null);
        }
        this._layer.renderAll();
    }

    exitEditMode() {
        this._textBoxCubit?.toggleEditMode(false);
    }

    updateText(newText) {
        if (this._text === newText) return;
        this._text = newText;
        this.save();
    }

    // #endregion

    update(annotation) {
        this._setAnnotation(annotation);
    }

    show() {
        this._figures.forEach(f => f.show());
        this._textBoxCubit?.toggleVisible(true);
    }

    hide() {
        this._figures.forEach(f => f.hide());
        this._textBoxCubit?.toggleVisible(false);
    }

    expand() {
        this._textBoxCubit?.toggleExpanded(true);
    }

    collapse() {
        this._textBoxCubit?.toggleExpanded(false);
    }

    hover(hovered, sender) {
        if (sender.isFigure) { this._textBoxCubit?.hover(hovered, false); }
        if (sender.isTextBox) { this._figures.forEach(f => f.hover(hovered, false)); }
    }

    highlight() {
        this._textBoxCubit?.toggleHighlight(true);
    }

    dropHighlight() {
        this._textBoxCubit?.toggleHighlight(false);
    }

    updateReactionsJson(reactionsJson) {
        this._annotation.reactionsJson = reactionsJson;
    };

    updateZoom() {
        this._figures.forEach(figure => figure.updateZoom(this._mainStage.zoom));
    }

    rerender() {
        this._figures.forEach(figure => figure.update());
        this._textBoxCubit?.updatePosition();
    }

    updateConnectingLines() {
        this._figures.forEach(figure => figure.updateConnectingLine());
        this.store.fabricLayer.renderAll();
    }

    changeColor(color) {
        this._color = annotationColors[color];
        this._calculateColorVariants();
        this._figures.forEach(figure => figure.updateColor());
        this.save();
    }

    removeSelectedFigure() {
        if (!this.hasActiveFigureToDelete) return;
        const activeFigure = this._figures.find(f => f.active);
        this.store.annotationManager.clearFigureSelection();
        this._figures = this._figures.filter(f => f.id !== activeFigure.id);
        activeFigure.dispose();
        this.save();
    }

    delete() {
        this.store.proofX.deleteComment(this._uid, true);
        this.dispose();
    }

    async save(skipWritingEvent) {
        if (!this._text) {
            this.dispose();
            return;
        }

        const figures = this._figures.map(f => ({
            type: f.figureType,
            id: f.id,
            basePoint: f.position,
            dimensions: f.dimensions,
        }));
        // TODO: replace this with single color value after moving away from the old Proof tool
        const colorName = Object.keys(annotationColors).find(c => annotationColors[c] === this._color);
        const { x: tx, y: ty } = this._textBoxCubit.position;
        const { width: tw, height: th } = this._textBoxCubit.size;

        const json = {
            id: this._id,
            baloonPosition: { x: tx, y: ty },
            baloonSize: { x: tw, y: th },
            figures,
            color: legacyColors[colorName],
            taggedUsers: this._textBoxCubit.taggedUsers,
        };
        if (!this._uid) {
            const { uid: assetUid, page: pageNum } = this.store.annotationManager._asset;
            await api.createAnnotation(assetUid, pageNum, this._text, JSON.stringify(json), false, this._tempUid);
            sendGTMEvent('Annotatate-Annotation-Added');
        } else {
            api.saveAnnotation(this._uid, this._text, JSON.stringify(json), skipWritingEvent);
            sendGTMEvent('Annotatate-Annotation-Edited');
        }
    }

    dispose() {
        const { annotationManager } = this.store;
        annotationManager.removeAnnotationCubit(this);
        this._textBoxCubit?.dispose();
        this._figures?.forEach(figure => {
            figure.dispose();
        });
    }

    _init(annotation) {
        const env = this.store.proofX.environment;
        const currentDate = new Date().toISOString();
        const currentDateData = this._formatDate(currentDate, getLocaleCode());
        this._color = annotationColors[annotation.color];
        this._calculateColorVariants();
        console.assert(this._color);
        annotation.userUid = env.userUid;
        annotation.userName = this.store.proofX.userName;
        this._tempUid = generateTempUid();
        this._annotation = annotation;
        this._date = `${currentDateData.date}, ${currentDateData.time}`;
    }

    _setAnnotation(annotation) {
        this._id = annotation.commentSeqId;
        this._uid = annotation.commentUid;
        this._text = annotation.body.replaceAll('<script', '&lt;script');
        const dateData = this._formatDate(annotation.created, getLocaleCode());
        this._date = `${dateData.date}, ${dateData.time}`;
        this._annotation = annotation;
        this._tempUid = null;
        if (!annotation.json) return;

        const data = JSON.parse(annotation.json);
        const colorName = Object.keys(legacyColors).find(c => legacyColors[c].mainColor === data.color.mainColor);
        this._color = annotationColors[colorName] ?? annotationColors.blue;
        this._calculateColorVariants();
        this._createOrUpdateTextBox(data.baloonPosition, { width: data.baloonSize?.x ?? 0, height: data.baloonSize?.y ?? 0 });
        data.figures.forEach(figureData => {
            let figure = this._figures.find(f => f.id === figureData.id);
            if (!figure) {
                figure = FigureFactory.create(figureData.type, figureData.id, this);
                this._figures.push(figure);
            }
            figure.setNew(false);
            figure.update(figureData.basePoint, figureData.dimensions);
        });
    }

    _initializeTextBox(figureCubit) {
        if (!figureCubit) return;

        const figure = figureCubit._figure;
        this._textBoxCubit = new TextBoxCubit(this);
        const boundingRect = figure.getBoundingRect(true, true);
        const position = getUnrotatedPoint({
            x: boundingRect.left + boundingRect.width / 2,
            y: boundingRect.top + boundingRect.height / 2,
        });
        this._textBoxCubit.mount(position);
        this._textBoxCubit.toggleEditMode(true);
    }

    _createOrUpdateTextBox(position, size) {
        if (!this._mainStage.osdViewer) return;
        if (this._textBoxCubit && this._textBoxCubit._anchorElement.parentElement) {
            this._textBoxCubit.updatePosition(position, size, true);
        } else {
            this._textBoxCubit = new TextBoxCubit(this);
            this._textBoxCubit.mount(position, size);
        }
    }

    _formatDate(dateString, localeCode = 'default') {
        if (!dateString) return;
        if (dateString.substring(dateString.length - 1) !== 'Z') dateString += 'Z';
        const dateTime = new Date(dateString);
        const day = dateTime.toLocaleString(localeCode, { day: '2-digit' });
        const month = dateTime.toLocaleString(localeCode, { month: 'short' });
        const year = dateTime.toLocaleString(localeCode, { year: 'numeric' });
        const fTime = new Intl.DateTimeFormat(localeCode, { hourCycle: 'h23', hour: '2-digit', minute: 'numeric' }).format(dateTime);
        return {
            date: `${day} ${month} ${year}`,
            time: fTime,
        };
    }

    _calculateColorVariants() {
        const color = this._color;
        const [hue, saturation, lightness] = convert.hex.hsl(color.substring(1).toUpperCase());
        const hoverColor = '#' + convert.hsl.hex([hue + 10, saturation, lightness + 20]);
        const shadowColor = '#' + convert.hsl.hex([hue, saturation - 50, 10]) + '99';
        const darkerColor = '#' + convert.hsl.hex([hue, saturation, lightness - 25]);
        this._colorVariants = { default: color, hover: hoverColor, selected: hoverColor, shadow: shadowColor, darker: darkerColor };
    }
}
