import { Check, X } from '@phosphor-icons/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useLayer, Arrow } from 'react-laag-v18';
import styled from 'styled-components';
import { FlexRow } from '../../styles';
import { useProofXStore } from '../Store/ProofXStore';
import { api } from '../Api/ProofXApi';

const Word = styled.span`
    border-bottom: ${({ $wrong }) => $wrong ? '2px dotted var(--error-color)' : ''};
    background-color: ${({ $selected }) => $selected ? 'var(--secondary-color)' : ''};
    cursor: pointer;

    :hover {
        background-color: ${({ $selected }) => $selected ? 'var(--secondary-color)' : 'var(--highlighted-background)'};
    }
`;

const Flyout = styled(motion.div)`
    border-radius: 8px;
    display: flex;
    font-size: 11px;
    background-color: var(--tooltip-background);
    box-shadow: var(--glass-shadow-popup);
    transform-origin: center center;
    z-index: 200000;
`;

const Command = styled(FlexRow)`
    border-radius: 8px;    
    padding: 4px;
    cursor: pointer;
    
    :hover {
        background-color: var(--highlighted-background);
    }
`;

const iconProps = {
    size: 16,
    color: 'var(--primary-color)',
    style: { marginRight: '4px' },
};

export default function SpellcheckerWord({ wrong, children, onMark }) {
    const [isOpen, setOpen] = useState(false);
    const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
        isOpen,
        onOutsideClick: () => setOpen(false), // close the menu when the user clicks outside
        onDisappear: () => setOpen(false), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => setOpen(false), // close the menu when the parent closes (e.g. a modal)
        overflowContainer: true, // make the tooltip flow above the container
        auto: true, // automatically find the best placement
        snap: true,
        placement: 'bottom-center', // we prefer to place the menu "top-end"
        possiblePlacements: ['bottom-center', 'top-center', 'center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 4, // give the menu some room to breath relative to the container
    });
    const strings = useProofXStore.getState().strings;

    const handleClick = (evt) => {
        setOpen(true);
    };

    const handleCommand = async () => {
        const store = useProofXStore.getState();
        const assetUid = store.assets?.main?.uid;
        const spellcheckerModal = store.proofX.spellcheckerModal;
        const langCode = spellcheckerModal.languageCode;
        const word = children.toString();
        setOpen(false);

        if (!assetUid || !langCode || !word) return;
        await api.markSpellcheckerWord(assetUid, word, isWrong, langCode);
        const msg = isWrong ? strings.wordMarkedAsCorrect : strings.wordMarkedAsIncorrect;
        spellcheckerModal.load();
        store.proofX.showAlert(msg.replace('{0}', word), 'success', 10000);
    };

    const isWrong = !!wrong;
    return (<>
        <Word {...triggerProps} $wrong={isWrong} $selected={isOpen} onClick={handleClick}>
            {children}
        </Word>
        {renderLayer(
            <div {...layerProps}>
                <AnimatePresence>
                    {isOpen && (
                        <Flyout
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{
                                duration: 0.15,
                                ease: 'easeInOut',
                            }}
                        >
                            <Command onClick={() => handleCommand()}>
                                {isWrong ? <Check {...iconProps} /> : <X {...iconProps} />}
                                {isWrong ? strings.markWordAsCorrect : strings.markWordAsIncorrect}
                            </Command>
                            <Arrow {...arrowProps} backgroundColor='var(--tooltip-background)' />
                        </Flyout>
                    )}
                </AnimatePresence>
            </div>,
        )}
    </>);
};
