import { action, computed, makeObservable, observable } from 'mobx';
import { getLocalization, setLocalization } from '../../localization/strings';
import AlertsStackCubit from '../AlertsStack/AlertsStackCubit';
import { api } from '../Api/ProofXApi';
import CompareModesManager from '../CompareModes/CompareModesManager';
import ApprovalModalCubit from '../Modals/ApprovalModalCubit';
import AttachmentViewerModalCubit from '../Modals/AttachmentViewerModalCubit';
import ConfirmModalCubit from '../Modals/ConfirmModalCubit';
import ExternalUserIntroModalCubit from '../Modals/ExternalUserIntroModalCubit';
import ShareModalCubit from '../Modals/ShareModalCubit';
import SpellcheckerModalCubit from '../Modals/SpellsheckerModalCubit';
import DiscussionPanelCubit from '../RightPanel/DiscussionPanel/DiscussionPanelCubit';
import FileInfoCubit from '../RightPanel/FileInfo/FileInfoCubit';
import SplitViewCubit from '../SplitView/SplitViewCubit';
import { useProofXStore } from '../Store/ProofXStore';
import ThumbnailsPanelCubit from '../ThumbnailsPanel/ThumbnailsPanelCubit';
import AnnotationManager from '../Viewer/AnnotationLayer/AnnotationManager';
import ViewerCubit, { ViewerModes } from '../Viewer/ViewerCubit';
import log, { logGroupEnd, logGroupStart } from './Logger';
import ProofXHub from './ProofXHub';
import sendGTMEvent from '../GTM';
import SuccessMessageModalCubit from '../Modals/SuccessMessageModalCubit';
import TourCubit from '../Tour/TourCubit';
import AddressBookRecordModalCubit from '../Modals/AddressBookRecordModalCubit';

export const ProofXState = {
    authenticating: 'authenticating',
    loading: 'loading',
    ready: 'ready',
    wrongLink: 'wrong link',
};

export const ReviewStatus = {
    Pending: 0,
    Closed: 1,
    Approved: 2,
    Rejected: 3,
    ApprovedWithChanges: 4,
};

export default class ProofXCubit {
    _state = ProofXState.authenticating;
    _userName = '';
    _progress = null;
    _theme = window.matchMedia('(prefers-color-scheme: dark)')?.matches ? 'Dark' : 'Light';
    _environment = {};
    _assets = [];
    _mainThumbnailsPanel = null;
    _signalRHub = null;
    _contentBlurred = false;
    _waitingMessage = '';
    _externalUserIntroModal = null;
    _confirmModal = null;
    _approvalModal = null;
    _shareModal = null;
    _successMessageModal = null;
    _attachmentViewerModal = null;
    _spellcheckerModal = null;
    _addressBookRecordModal = null;
    _selectiveApprovalCheckedAssets = [];
    _urlAttributes = null;
    _loggedInUsers = [];
    _isApprovalInProgress = false;
    _isTourReady = false;

    constructor() {
        makeObservable(this, {
            _state: observable,
            _userName: observable,
            _progress: observable,
            _theme: observable,
            _environment: observable.ref,
            _assets: observable.ref,
            _signalRHub: observable.ref,
            _contentBlurred: observable,
            _externalUserIntroModal: observable.ref,
            _confirmModal: observable.ref,
            _approvalModal: observable.ref,
            _successMessageModal: observable.ref,
            _shareModal: observable.ref,
            _attachmentViewerModal: observable.ref,
            _spellcheckerModal: observable.ref,
            _addressBookRecordModal: observable.ref,
            _waitingMessage: observable,
            _loggedInUsers: observable,
            _selectiveApprovalCheckedAssets: observable,
            _isApprovalInProgress: observable,
            _isTourReady: observable,

            environment: computed,
            userName: computed,
            isLoading: computed,
            isWrongLink: computed,
            theme: computed,
            isDarkMode: computed,
            assets: computed,
            progress: computed,
            contentBlurred: computed,
            waitingMessage: computed,
            externalUserIntroModal: computed,
            confirmModal: computed,
            approvalModal: computed,
            successMessageModal: computed,
            shareModal: computed,
            isAnyAssetPageChecked: computed,
            attachmentViewerModal: computed,
            spellcheckerModal: computed,
            addressBookRecordModal: computed,
            loggedInUsers: computed,
            isSelectAllAssetsChecked: computed,
            isApprovalInProgress: computed,
            isTourReady: computed,

            setLoading: action,
            setReady: action,
            setUserName: action,
            setWrongLink: action,
            toggleTheme: action,
            setEnvironment: action,
            setAssets: action,
            blurContent: action,
            unblurContent: action,
            showConfirm: action,
            setWaitingMessage: action,
            setTourReady: action,
            openAttachmentViewer: action,
            toggleSelectiveApprovalAssetCheck: action,
            toggleSelectAllAssetsChecked: action,
            openShareDialog: action,
            _openApproveDialog: action,
            _init: action,
            _setExternalUserIntroModal: action,
            _setConfirmModal: action,
            _setAttachmentViewModal: action,
            _setApprovalModal: action,
            _setSuccessMessageModal: action,
            _setShareModal: action,
            _setSpellcheckerModal: action,
            _setAddressBookRecordModal: action,
            _setLoggedInUsers: action,
            _setTheme: action,
            _setApprovalInProgress: action,
        });
        this._init();
    };

    // #region Interface

    get isAuthenticating() { return this._state === ProofXState.authenticating; }
    get isLoading() { return this._state === ProofXState.loading; }
    get progress() { return this._progress; }
    get isWrongLink() { return this._state === ProofXState.wrongLink; }
    get theme() { return this._theme; }
    get isDarkMode() { return this._theme === 'Dark'; }
    get environment() { return this._environment; }
    get assets() { return this._assets; }
    get contentBlurred() { return this._contentBlurred; }
    get waitingMessage() { return this._waitingMessage; }
    get externalUserIntroModal() { return this._externalUserIntroModal; }
    get confirmModal() { return this._confirmModal; }
    get approvalModal() { return this._approvalModal; }
    get successMessageModal() { return this._successMessageModal; }
    get shareModal() { return this._shareModal; }
    get store() { return useProofXStore.getState(); }
    get attachmentViewerModal() { return this._attachmentViewerModal; }
    get spellcheckerModal() { return this._spellcheckerModal; }
    get addressBookRecordModal() { return this._addressBookRecordModal; }
    get userName() { return this._userName; }
    get loggedInUsers() { return this._loggedInUsers; }
    get isMobile() { return window.isMobileBrowser; }
    get isTourReady() { return this._isTourReady; }
    get isAnyAssetCommented() {
        return this.assets?.reduce(
            (totalComments, asset) => totalComments + asset?.pages.reduce(
                (assetComments, page) => assetComments + (page?.numberOfComments) ?? 0, 0,
            ) ?? 0,
            0) > 0;
    }

    get showApprovalTools() {
        const env = this.environment;
        return env && !env.flags.isReadOnly && (env.project?.isLiteMode
            ? !env.task || env.task.statusCode === ReviewStatus.Pending
            : !env.flags.isQuickView && env.task?.statusCode === ReviewStatus.Pending
        );
    }

    get isTaskCompleted() {
        const env = this.environment;
        return env?.task && env.task.statusCode !== ReviewStatus.Pending;
    }

    get isSelectiveApproval() {
        return this.assets.length > 1 &&
            (this._environment?.approvalSettings?.isSelectiveApproval ?? false);
    }

    get isAnyAssetPageChecked() { return this._selectiveApprovalCheckedAssets.length > 0; }
    get isSelectAllAssetsChecked() { return this._selectiveApprovalCheckedAssets.length === this.assets.length; }
    get isApprovalInProgress() { return this._isApprovalInProgress; }

    getThumbnailIndex({ uid, page }) {
        const index = this.assets.findIndex(a => a.uid === uid);
        const prevPages = [...this.assets]
            .splice(0, index)
            .map(a => a.pages.length)
            .reduce((s, n) => s + n, 0);
        return prevPages + page;
    }

    blurContent() { this._contentBlurred = true; }
    unblurContent() { this._contentBlurred = false; }

    showConfirm(title, message, confirmText, onConfirm) {
        this._confirmModal.show(title, message, confirmText, onConfirm);
    }

    openShareDialog() {
        this._shareModal.show();
        sendGTMEvent('ShareMenu-Clicked-on-Share');
    }

    downloadAsset() {
        if (!this.store.assets.main) return;
        const { uid } = this.store.assets.main;
        api.downloadAsset(uid);
        sendGTMEvent('RTUtilities-Download-Asset');
    }

    showSpellcheckerModal() {
        this._spellcheckerModal.show();
        sendGTMEvent('RTUtilities-SpellCheck');
    }

    openAttachmentViewer(commentCubit) {
        this._attachmentViewerModal.show(commentCubit);
    }

    async loadComments(assetUid, page) {
        const { annotationManager, discussionPanel } = this.store;
        const comments = await api.fetchAnnotations(assetUid, page);
        if (!comments) {
            this._setError(`Fetching comments for asset ${assetUid}, page ${page} is failed`);
        }
        // make sure we got comments from the right asset page
        const currentAsset = this.store.assets.main;
        if (currentAsset.uid !== assetUid || currentAsset.page !== page) return;
        // if (comments.filter(c => c.assetUid === currentAsset.uid && c.pageNumber === currentAsset.page).length === 0) return;

        const filteredComments = this.environment.flags.isExternalReview
            ? comments.filter(c => !c.isInternal)
            : comments;
        useProofXStore.setState({ assetComments: filteredComments });

        annotationManager?.updateAnnotations();
        discussionPanel?.updateDiscussion();
    }

    async loadProjectDiscussion() {
        const { discussionPanel, splitView } = this.store;
        const comments = await api.fetchProjectDiscussion(this.environment.projectUid);
        if (!comments) {
            this._setError(`Fetching project discussion (${this.environment.projectUid}) is failed`);
        }
        const filteredComments = this.environment.flags.isExternalReview
            ? comments.filter(c => !c.isInternal)
            : comments;
        useProofXStore.setState({ projectComments: filteredComments });

        if (discussionPanel.isAssetComments) return;
        discussionPanel?.updateDiscussion();

        if (comments.length > 0) {
            splitView.openRightDrawer();
        }
        sendGTMEvent('Discussion-Discussion-Clicked');
    }

    async loadSingleComment(commentUid, tempUid) {
        const { annotationManager, discussionPanel, assetComments, projectComments, assets: { main: mainAsset } } = this.store;
        const comment = await api.fetchSingleAnnotation(commentUid);
        if (!comment) {
            this._setError(`Fetching comment ${commentUid} is failed`);
            return;
        }

        if (comment.isProjectDiscussion) {
            const foundProjectComment = projectComments.find(a => a.commentUid === comment.commentUid);
            useProofXStore.setState({
                projectComments: foundProjectComment
                    ? projectComments.map(a =>
                        a.commentUid === comment.commentUid ? comment : a)
                    : [...projectComments, comment],
            });
        } else {
            if (mainAsset.uid !== comment.assetUid || mainAsset.page !== comment.pageNumber) return;
            if (tempUid) {
                comment.tempUid = tempUid;
            }
            const foundAssetComment = assetComments.find(a => a.commentUid === comment.commentUid);
            useProofXStore.setState({
                assetComments: foundAssetComment
                    ? assetComments.map(a =>
                        a.commentUid === comment.commentUid ? comment : a)
                    : [...assetComments, comment],
            });
        }

        annotationManager?.updateAnnotations();
        discussionPanel?.updateDiscussion();
    }

    async deleteComment(commentUid, isAssetComment) {
        const { assetComments, projectComments } = this.store;
        const uidsToDelete = this._getCommentsSubtreeUids(commentUid, isAssetComment);
        if (isAssetComment) {
            useProofXStore.setState({
                assetComments: assetComments.filter(c => !uidsToDelete.includes(c.commentUid)),
            });
        } else {
            useProofXStore.setState({
                projectComments: projectComments.filter(c => !uidsToDelete.includes(c.commentUid)),
            });
        }
        api.deleteAnnotation(commentUid);
        sendGTMEvent(`${isAssetComment ? 'Annotatate-Annotation' : 'Discussion-Discussion'}-Deleted`);
    }

    showAlert(message, severity, dismissInterval) {
        this.store.alertsStack.addAlert(message, severity, dismissInterval);
    }

    // #region Approval

    toggleSelectiveApprovalAssetCheck(assetUid) {
        if (this.isSelectiveApprovalAssetChecked(assetUid)) {
            this._selectiveApprovalCheckedAssets = this._selectiveApprovalCheckedAssets
                .filter(a => a !== assetUid);
        } else {
            this._selectiveApprovalCheckedAssets = [
                ...this._selectiveApprovalCheckedAssets,
                assetUid,
            ];
        }
    }

    toggleSelectAllAssetsChecked() {
        if (this.isSelectAllAssetsChecked) {
            this._selectiveApprovalCheckedAssets = [];
        } else {
            this._selectiveApprovalCheckedAssets = this.assets.map(a => a.uid);
        }
    }

    isSelectiveApprovalAssetChecked(assetUid) {
        return !!this._selectiveApprovalCheckedAssets
            .find(a => a === assetUid);
    }

    async handleApprove() {
        this._openApproveDialog(
            ReviewStatus.Approved,
            this.proceedWithApproval.bind(this),
        );
        sendGTMEvent('Decision-buttons-Approve-button-clicked');
    }

    async handleApproveWithChanges() {
        this._openApproveDialog(
            ReviewStatus.ApprovedWithChanges,
            this.proceedWithApprovalWithChanges.bind(this),
        );
        sendGTMEvent('Decision-buttons-Approve-with-changes-button-clicked');
    }

    async handleRequestChanges() {
        const operationPermitted = await api.checkUserHasCommentsInAnyAsset(this.assets.map(a => a.uid));
        if (!operationPermitted) {
            const strings = this.store.strings;
            this.showConfirm(
                strings.commentsAreRequired,
                strings.youHaveToCommentAssetBeforeRequestingChanges,
                strings.ok,
            );
            return;
        }
        this._openApproveDialog(
            ReviewStatus.Rejected,
            this.proceedWithRequestChanges.bind(this),
        );
        sendGTMEvent('Decision-buttons-Reject-button-clicked');
    }

    async proceedWithApproval() {
        const env = this.environment;
        if (this.assets.length === 1 && !this._selectiveApprovalCheckedAssets.includes(this.assets[0].uid)) {
            this._selectiveApprovalCheckedAssets.push(this.assets[0].uid);
        };
        const { approvedAssetUidsCSV, rejectedAssetUidsCSV } = this._getApprovedAndRejectedAssetUids();
        if (this.isApprovalInProgress) return;
        this._setApprovalInProgress(true);
        await api.changeApprovalStatus(env.taskUid, ReviewStatus.Approved, approvedAssetUidsCSV, rejectedAssetUidsCSV, '');
        this._showApprovalSuccessMessage();
    }

    async proceedWithApprovalWithChanges(checklistItems) {
        const env = this.environment;
        if (this.assets.length === 1 && !this._selectiveApprovalCheckedAssets.includes(this.assets[0].uid)) {
            this._selectiveApprovalCheckedAssets.push(this.assets[0].uid);
        };
        const { approvedAssetUidsCSV, rejectedAssetUidsCSV } = this._getApprovedAndRejectedAssetUids();
        if (this.isApprovalInProgress) return;
        const checkedItems = checklistItems?.join('||') ?? '';
        this._setApprovalInProgress(true);
        await api.changeApprovalStatus(env.taskUid, ReviewStatus.ApprovedWithChanges, approvedAssetUidsCSV, rejectedAssetUidsCSV, checkedItems);
        this._showApprovalSuccessMessage();
    }

    async proceedWithRequestChanges(checklistItems) {
        const env = this.environment;
        if (this.assets.length === 1 && this._selectiveApprovalCheckedAssets.includes(this.assets[0].uid)) {
            this._selectiveApprovalCheckedAssets = [];
        };
        const { approvedAssetUidsCSV, rejectedAssetUidsCSV } = this._getApprovedAndRejectedAssetUids();
        if (this.isApprovalInProgress) return;
        const checkedItems = checklistItems?.join('||') ?? '';
        this._setApprovalInProgress(true);
        await api.changeApprovalStatus(env.taskUid, ReviewStatus.Rejected, approvedAssetUidsCSV, rejectedAssetUidsCSV, checkedItems);
        this._showRejectionSuccessMessage();
    }

    // #endregion

    // #region setters

    setLoading() { this._state = ProofXState.loading; }
    setReady() {
        this._state = ProofXState.ready;
        this._waitingMessage = '';
    }

    setWaitingMessage(message) {
        this._waitingMessage = message;
        this._state = ProofXState.loading;
    }

    setWrongLink() { this._state = ProofXState.wrongLink; }
    setUserName(name) { this._userName = name; }
    setEnvironment(env) { this._environment = env; }
    setAssets(newAssets) { this._assets = newAssets; }
    setTourReady(isTrue) { this._isTourReady = isTrue; }

    toggleTheme() { // manually toggle UI theme. We save user's choice in cookie
        this._theme = this._theme === 'Dark' ? 'Light' : 'Dark';
        document.cookie = `proofXTheme=${this._theme};path=/`;
    }

    _setConfirmModal(cubit) { this._confirmModal = cubit; }
    _setExternalUserIntroModal(cubit) { this._externalUserIntroModal = cubit; }
    _setApprovalModal(cubit) { this._approvalModal = cubit; }
    _setSuccessMessageModal(cubit) { this._successMessageModal = cubit; }
    _setShareModal(cubit) { this._shareModal = cubit; }
    _setSpellcheckerModal(cubit) { this._spellcheckerModal = cubit; }
    _setAttachmentViewModal(cubit) { this._attachmentViewerModal = cubit; }
    _setAddressBookRecordModal(cubit) { this._addressBookRecordModal = cubit; }
    _setLoggedInUsers(users) { this._loggedInUsers = users; }
    _setTheme(theme) { this._theme = theme; }
    _setApprovalInProgress(isInProgress) { this._isApprovalInProgress = isInProgress; }

    // #endregion

    // #endregion

    // #region Private

    async _init() {
        logGroupStart('🎆 ============ Initializing ProofX... ============');
        try {
            // get custom theme settings from cookie
            const cookieTheme = document.cookie.split(';')
                .find(c => c.includes('proofXTheme'))
                ?.split('=')[1];

            this._setTheme(cookieTheme ?? this._theme);
            this._addResizeListener();

            // obtain parameters from url
            const [projectUid, taskUid, sessionToken, assetUids, pageNumber] = location.pathname.split('/').splice(1);
            this._urlAttributes = { projectUid, taskUid, sessionToken, assetUids, pageNumber };
            log('💾 parameters obtained:', { projectUid, taskUid, sessionToken, assetUids, pageNumber });
            if (!projectUid || !taskUid || !sessionToken) {
                return this._processError('Required parameters are missing');
            }

            // check if parameters are pointing to a valid session
            const { isSessionValid } = await api.checkSessionIntegrity(projectUid, taskUid, sessionToken);
            if (!isSessionValid) { return this._processError('Session is invalid'); }

            document.cookie = `proofXSession=${sessionToken};path=/;samesite=strict`;

            this.setLoading();

            // fetch environment
            const environment = await api.fetchEnvironment(projectUid, taskUid);
            if (!environment || environment?.error?.length > 0) { return this._processError(environment?.error ?? 'Error while fetching environment'); }
            this.setEnvironment(environment);
            setLocalization(environment.language);
            window.userUid = environment.userUid;
            const envTheme = environment.flags.isDarkMode ? 'Dark' : 'Light';
            this._setTheme(cookieTheme ?? envTheme);

            // fetch assets thumbnails
            await this._loadAssets(assetUids && assetUids !== 'project_discussion' ? assetUids : taskUid);
            this._initializeStoreObjects();
            document.title = `${this.store.strings.projectName} ${environment.project.name} | Approval Studio`;

            await this._obtainUserName();
            await this._initializeSignalR(projectUid, sessionToken, this._userName);

            // open thumbnails panel if there's more than one asset or more than one page in an asset
            if (!this.isMobile) {
                if (this.assets.length > 1 || this.assets[0].pages.length > 1) {
                    this.store.splitView.openLeftDrawer();
                }
                this.store.splitView.openRightDrawer();
            }
            this.store.mainThumbnailsPanel.selectFirstAssetPage(pageNumber ?? 0);

            if (assetUids === 'project_discussion') {
                this.store.viewer?.setMode(ViewerModes.projectDiscussion);
                this.store.splitView.openRightDrawer();
            }

            // check if GTM analytics is enabled

            const GTMStatus = await api.checkGTMEnabled();
            window.isGTMEnabled = GTMStatus?.isGTMEnabled ?? false;

            // set state to ready to give control to internal components
            this.setReady();
            document.body.style.background = 'var(--default-background-gradient)';
        } catch (err) {
            this._processError(err);
        } finally {
            logGroupEnd();
        }
    }

    _addResizeListener() {
        const viewport = window.visualViewport;
        document.body.style.setProperty('--app-height', `${viewport.height}px`);
        viewport.addEventListener('resize', () => {
            log('📏 resizing height to', viewport.height);
            document.body.style.setProperty('--app-height', `${viewport.height}px`);
        });
    }

    async _loadAssets(taskUid) {
        const assets = await api.fetchAssets(taskUid.toUpperCase(), this.environment.flags.pdfLayersEnabled);
        if (!assets || assets?.error?.length > 0) { return this._processError('Error loading assets'); }
        try {
            const assetsModel = assets.map(asset => ({
                uid: asset.assetId,
                title: asset.fileName,
                version: asset.version,
                layersExist: asset.hasLayers,
                layersLoaded: asset.layersLoaded,
                pages: asset.pages.map((p, i) => ({
                    pageNum: p.pageNumber,
                    numberOfComments: p.numberOfComments,
                    thumbnail: p.thumbBytes.replace(/^.+,/, ''),
                    layers: asset.layers?.find(l => l.pageNum === p.pageNumber - 1)?.layers,
                })),
            }));
            this.setAssets(assetsModel);
        } catch (error) {
            console.error('Error loading assets', error);
        };
    }

    _initializeStoreObjects() {
        const viewer = new ViewerCubit();
        const splitView = new SplitViewCubit();
        const mainThumbnailsPanel = new ThumbnailsPanelCubit(this.assets);
        const compareThumbnailsPanel = new ThumbnailsPanelCubit(this.assets, true);
        const discussionPanel = new DiscussionPanelCubit();
        const fileInfo = new FileInfoCubit();
        const annotationManager = new AnnotationManager();
        const compareModesManager = new CompareModesManager();
        const alertsStack = new AlertsStackCubit();
        const tourCubit = new TourCubit();

        this._setConfirmModal(new ConfirmModalCubit());
        this._setExternalUserIntroModal(new ExternalUserIntroModalCubit());
        this._setApprovalModal(new ApprovalModalCubit());
        this._setSuccessMessageModal(new SuccessMessageModalCubit());
        this._setShareModal(new ShareModalCubit());
        this._setSpellcheckerModal(new SpellcheckerModalCubit());
        this._setAttachmentViewModal(new AttachmentViewerModalCubit());
        this._setAddressBookRecordModal(new AddressBookRecordModalCubit());

        viewer.setBackgroundPattern(this.environment.defaultBackground ?? 'default');

        useProofXStore.setState({
            strings: getLocalization(),
            ...{
                tourCubit,
                mainThumbnailsPanel,
                compareThumbnailsPanel,
                discussionPanel,
                fileInfo,
                annotationManager,
                compareModesManager,
                viewer,
                splitView,
                alertsStack,
            },
        });
    }

    async _initializeSignalR(projectUid, sessionToken, userName) {
        this._signalRHub = new ProofXHub({
            sessionToken,
            userName,
            projectUid,
            isExternal: this.environment.flags.isExternalReview,
            eventHandlers: {
                OnProjectCommentsUpdated: this._handleCommentsUpdated.bind(this),
                OnTaskStatusUpdated: this._handleTaskStatusUpdated.bind(this),
                OnViewerThumbnailsUpdated: this._handleAssetThumbnailsUpdated.bind(this),
                OnProoftoolOnlineUsersUpdated: this._handleProoftoolOnlineUsersUpdated.bind(this),
            },
        });
        await this._signalRHub._initialize();
    }

    async _handleCommentsUpdated(projectUid, commentUid, tempUid) {
        if (this.environment.projectUid !== projectUid) return;
        const { viewer, assets: { main: mainAsset } } = this.store;

        if (commentUid) {
            this.loadSingleComment(commentUid, tempUid);
        } else {
            if (viewer) {
                this.loadComments(mainAsset.uid, mainAsset.page);
            }
            this.loadProjectDiscussion();
        }
        // reloading assets to get annotation counters updated
        // TODO: optimize traffic by loading only annotation counters without thumbnails data etc.
        await this._loadAssets(this._urlAttributes.taskUid);
        this.store.mainThumbnailsPanel.updateAssets(this._assets);
    }

    async _handleTaskStatusUpdated(projectUid, originalTaskUid, resultTaskUid, sessionToken) {
        if (sessionToken && sessionToken !== this._urlAttributes.sessionToken) return;
        if (originalTaskUid && originalTaskUid !== this._urlAttributes.taskUid) return;

        const hadApprovalToolsBeforeChange = this.showApprovalTools;
        // if resultTaskUid contains comma-separated uids, this is selective review,
        // so we take just the first uid
        resultTaskUid = resultTaskUid?.split(',')[0] ?? null;
        const environment = await api.fetchEnvironment(projectUid ?? this.environment.projectUid, resultTaskUid ?? this.environment.taskUid);
        if (!environment || environment?.error?.length > 0) { return this._processError(environment?.error ?? 'Error while fetching environment'); }
        this.setEnvironment(environment);
        if (resultTaskUid && this._urlAttributes.taskUid !== resultTaskUid) {
            const urlParams = location.pathname.split('/');
            urlParams[2] = resultTaskUid;
            history.pushState(null, null, urlParams.join('/'));
        }

        if (hadApprovalToolsBeforeChange && !this.showApprovalTools) {
            this.showAlert(this.store.strings.taskWasClosed, 'info');
        }
    }

    async _handleAssetThumbnailsUpdated() {
        await this._loadAssets(this._urlAttributes.taskUid);
        this.store.mainThumbnailsPanel.updateAssets(this._assets);
    }

    _handleProoftoolOnlineUsersUpdated(users) {
        this._setLoggedInUsers(users);
    }

    _obtainUserName() {
        return new Promise((resolve, reject) => {
            const env = this.environment;
            const ownerName = env.allClientUsers.find(u => u.key === env.userUid)?.value ?? '';
            if (!ownerName) {
                this._processError(new Error(`Unknown user ${env.userUid}`));
                reject(new Error(`Unknown user ${env.userUid}`));
                return;
            }
            if (env.flags.isCommonExternalReview) {
                this._externalUserIntroModal.show(
                    async (name) => {
                        await this._handleExternalUserNameObtained(name, ownerName);
                        resolve();
                    },
                );
            } else if (env.flags.isExternalReview) {
                this.setUserName(`${env.externalUser} (by ${ownerName})`);
                resolve();
            } else {
                this.setUserName(ownerName);
                resolve();
            }
        });
    }

    async _handleExternalUserNameObtained(name, ownerName) {
        const result = await api.saveExternalUserName(this.environment.taskUid, name);
        if (result?.token) {
            document.cookie = `proofXSession=${result.token};path=/;samesite=strict`;
            const urlParams = location.pathname.split('/');
            urlParams[3] = result.token;
            this._urlAttributes.sessionToken = result.token;
            history.pushState(null, null, urlParams.join('/'));
        }
        this.setUserName(`${name} (by ${ownerName})`);
    }

    _showApprovalSuccessMessage() {
        const opts = this.environment.approvalSettings;
        if (!opts.approvalSuccessMessage) return;
        this._successMessageModal.show(opts.approvalSuccessMessage, opts.approvalSuccessCloseable);
    }

    _showRejectionSuccessMessage() {
        const opts = this.environment.approvalSettings;
        if (!opts.rejectionSuccessMessage) return;
        this._successMessageModal.show(opts.rejectionSuccessMessage, opts.rejectionSuccessCloseable);
    }

    _openApproveDialog(action, onConfirm) {
        this._approvalModal.show(action, onConfirm);
    }

    _processError(err) {
        console.error('Failed to initialize ProofX. Error:', err);
        this.setWrongLink();
    }

    _getCommentsSubtreeUids(commentUid, isAssetComment) {
        const source = isAssetComment ? this.store.assetComments : this.store.projectComments;
        return [
            commentUid,
            ...source
                .filter(c => c.parentCommentUid === commentUid)
                .map(c => this._getCommentsSubtreeUids(c.commentUid, isAssetComment)),
        ].flat();
    }

    _getApprovedAndRejectedAssetUids() {
        const isSelective = this._environment?.approvalSettings?.isSelectiveApproval ?? false;
        const approvedAssetUidsCSV = isSelective
            ? this._selectiveApprovalCheckedAssets.join(',')
            : this._assets.map(a => a.uid).join(',');
        const rejectedAssetUidsCSV = isSelective
            ? this._assets.filter(a => !this._selectiveApprovalCheckedAssets.includes(a.uid)).map(a => a.uid).join(',')
            : '';
        return { approvedAssetUidsCSV, rejectedAssetUidsCSV };
    }

    // #endregion
}
