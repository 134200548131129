import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useLayer } from 'react-laag-v18';
import { AnimatePresence, motion } from 'framer-motion';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Input } from '../Input';
import Tooltip from '../Tooltip';
import { useProofXStore } from '../../Store/ProofXStore';
import { Pencil, XCircle } from '@phosphor-icons/react';

const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px;
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: 10px;
`;
const EmailInput = styled(Input)`
    border: none;
    padding: 4px;
    min-width: 100px;        
    outline: none;
    background-color: transparent;
    flex-grow: 1;
`;
const Chip = styled.div`
    background-color: var(--highlighted-background);
    border-radius: 10px;
    padding: 4px 4px 4px 8px;
    border: 1px solid var(--input-border-color);
    margin: 4px;
    display: flex;
    flex-direction: row; 
    align-items: center;
`;
const RemoveButton = styled(XCircle).attrs({ size: 18, weight: 'fill', color: 'var(--secondary-color)' })`
    cursor: pointer;
    margin-left: 4px;
`;
const EditRecordButton = styled(Pencil).attrs({ size: 18, weight: 'fill', color: 'var(--secondary-color)' })`
    cursor: pointer;
    margin-left: 4px;
`;
const Trigger = styled.div`
`;
const Flyout = styled(motion.div)`
    position: relative;
    border-radius: 8px;        
    padding: 6px;    
    background-color: var(--popover-background);
    box-shadow: var(--glass-shadow-popup);
    transform-origin: center center;
    max-height: 300px;
    width: 300px;
`;

const OptionsList = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 11px;
`;

const Option = styled.div`
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;    
    background-color: ${({ selected }) => selected ? 'var(--highlighted-background)' : ''};
    cursor: pointer;

    :hover {
        background-color: ${({ selected }) => selected ? 'var(--highlighted-background)' : 'var(--glass-background)'};
    }
`;

export default observer(function EmailPicker({ cubit }) {
    const inputRef = useRef(null);
    const strings = useProofXStore.getState().strings;
    const { renderLayer, triggerProps, layerProps } = useLayer({
        isOpen: cubit.isOpen,
        onOutsideClick: () => cubit.close(), // close the menu when the user clicks outside
        onDisappear: () => cubit.close(), // close the menu when the menu gets scrolled out of sight
        onParentClose: () => cubit.close(), // close the menu when the parent closes (e.g. a modal)
        overflowContainer: false, // keep the menu positioned inside the container
        auto: true, // automatically find the best placement
        placement: 'bottom-start', // we prefer to place the menu "top-end"
        possiblePlacements: ['bottom-start', 'top-start', 'right-center'],
        triggerOffset: 6, // keep some distance to the trigger
        containerOffset: 4, // give the menu some room to breath relative to the container
    });

    useEffect(() => {
        const node = document.getElementById(`autocomplete-option-${cubit.selectionIndex}`);
        node?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [cubit.selectionIndex]);

    return (<>
        <Wrapper onClick={inputRef.current?.focus()}>
            {cubit?.addedRecords.map(e => (
                <Chip key={e.email}>
                    {e.name ? `${e.name} (${e.email})` : e.email}
                    <Tooltip text={strings.editAddressBookRecord} style={{ display: 'flex' }}>
                        <EditRecordButton onClick={() => cubit.editRecord(e)} />
                    </Tooltip>
                    <Tooltip text={strings.remove} style={{ display: 'flex' }}>
                        <RemoveButton onClick={() => cubit.removeRecord(e.email)} />
                    </Tooltip>
                </Chip>
            ))}
            {!cubit?.isLimitReached && <>
                <Trigger {...triggerProps} />
                <EmailInput
                    ref={inputRef}
                    value={cubit.inputText}
                    placeholder={strings.email}
                    maxLength={255}
                    onChange={(e) => cubit.handleChange(e.target.value)}
                    onBlur={() => cubit.handleInputBlur()}
                    onKeyDown={(e) => cubit.handleKeyDown(e)}
                />
                {renderLayer(
                    <div {...layerProps}>
                        <AnimatePresence>
                            {cubit.isOpen && cubit.visibleRecords?.length > 0 && (
                                <Flyout
                                    initial={{ opacity: 0, scale: 0 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0 }}
                                    transition={{
                                        duration: 0.25,
                                        type: 'spring',
                                        bounce: 0.35,
                                    }}
                                >
                                    <SimpleBar style={{ maxHeight: 300 }}>
                                        <OptionsList>
                                            {cubit.visibleRecords.map((o, i) => (
                                                <Option key={o.email} selected={cubit.selectionIndex === i}
                                                    onClick={() => { cubit.complete(o); }}
                                                    onMouseOver={() => cubit.setSelectionIndex(i)}>
                                                    {o.name ? `${o.name} (${o.email})` : o.email}
                                                </Option>
                                            ))}
                                        </OptionsList>
                                    </SimpleBar>
                                </Flyout>
                            )}
                        </AnimatePresence>
                    </div>,
                )}
            </>}
        </Wrapper >
    </>);
});
