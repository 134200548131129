import { observable, computed, action, makeObservable } from 'mobx';
import { api } from '../../Api/ProofXApi';
import { useProofXStore } from '../../Store/ProofXStore';
import EditorCubit from '../../uicontrols/CommentEditor/EditorCubit';
import DropdownMenuCubit from '../../uicontrols/DropdownMenuCubit';
import sendGTMEvent from '../../GTM';

export default class CommentCubit {
    _parent = null;
    _comment = null;
    _fileInput = null;
    _color = 'transparent';
    _level = 0;
    _showReplies = true;
    _showNewReply = false;
    _replies = [];
    _editMode = false;
    _editor = null;
    _menuCubit = null;
    _editor = null;
    _highlighted = false;
    _replyEditor = null;
    _uploadProgress = null;
    _uploadError = null;

    constructor(comment) {
        makeObservable(this, {
            _parent: observable.ref,
            _comment: observable.ref,
            _fileInput: observable.ref,
            _level: observable,
            _color: observable,
            _replies: observable,
            _showReplies: observable,
            _showNewReply: observable,
            _editMode: observable,
            _menuCubit: observable.ref,
            _uploadProgress: observable,
            _uploadError: observable,
            _highlighted: observable,

            comment: computed,
            userName: computed,
            isExternalUser: computed,
            parent: computed,
            color: computed,
            replies: computed,
            showReplies: computed,
            showNewReply: computed,
            editMode: computed,
            editMenu: computed,
            editable: computed,
            deletable: computed,
            isComplete: computed,
            isInternal: computed,
            uploadProgress: computed,
            uploadError: computed,
            highlighted: computed,
            reactions: computed,

            setComment: action,
            setColor: action,
            setParent: action,
            setLevel: action,
            toggleCollapseReplies: action,
            setReplies: action,
            openNewReply: action,
            closeNewReply: action,
            submitReply: action,
            cancelReply: action,
            toggleEditMode: action,
            doneEditing: action,
            cancelEdit: action,
            deleteComment: action,
            setUploadProgress: action,
            setUploadError: action,
            highlight: action,
            dropHighlight: action,
        });
        this._comment = comment;
        if (comment.json) {
            const metadata = JSON.parse(comment.json);
            if (metadata.color) {
                this._color = metadata.color.mainColor;
            }
        }
        this._editor = new EditorCubit(this.id, this.doneEditing.bind(this), this.cancelEdit.bind(this));
        this._replyEditor = new EditorCubit(`reply-${this.id}`, this.submitReply.bind(this), this.cancelReply.bind(this));
        this._menuCubit = new DropdownMenuCubit(this);
    };

    get id() { return this._comment.commentUid; }
    get frameElement() { return document.getElementById(`comment-frame-${this.id}`); }
    get comment() { return this._comment; }
    get userName() { return this._comment.userName; }
    get isExternalUser() { return !!this.userName.match(/[^(]+\(by[^)]+\)/g); }
    get parent() { return this._parent; }
    get level() { return this._level; }
    get color() { return this._color; }
    get replies() { return this._replies; }
    get showReplies() { return this._showReplies; }
    get showNewReply() { return this._showNewReply; }
    get replyEditor() { return this._replyEditor; }
    get editor() { return this._editor; }
    get editMode() { return this._editMode; }
    get editMenu() { return this._menuCubit; }
    get isComplete() { return this._comment?.isComplete; }
    get isInternal() { return this._comment?.isInternal; }
    get attachment() { return this._comment?.attachment; }
    get store() { return useProofXStore.getState(); }
    get _discussion() { return useProofXStore.getState().discussionPanel?.discussionCubit; }
    get allChildrenUids() { return [this.id, ...this.replies.map(c => c.allChildrenUids)].flat(); }
    get uploadProgress() { return this._uploadProgress; }
    get uploadError() { return this._uploadError; }
    get highlighted() { return this._highlighted; }
    get ownedByMe() { return this._comment?.userUid === this.store.proofX.environment.userUid && this.userName === this.store.proofX.userName; }
    get reactions() { return this._comment?.reactionsJson ? JSON.parse(this._comment.reactionsJson) : []; }
    get editable() {
        const { proofX } = this.store;
        return !proofX.isTaskCompleted && this.ownedByMe;
    }

    get deletable() {
        return this.editable && this._replies.length === 0;
    }

    setParent(parent) { this._parent = parent; }
    setLevel(level) { this._level = level; }
    setComment(comment) { this._comment = comment; }
    setColor(color) { this._color = color; }
    setReplies(commentsTree) { this._replies = commentsTree; }
    toggleCollapseReplies(showReplies) { this._showReplies = showReplies ?? !this._showReplies; }

    toggleEditMode(isOn) {
        if (!this._editor) {
            this._editor = new EditorCubit(this.id, this.doneEditing.bind(this), this.cancelEdit.bind(this));
        }
        if (isOn) {
            this.store.discussionPanel?.discussionCubit.cancelEditAll();
            this._editMode = true;
            this._editor.initWithHtml(this.comment.body);
            if (this.text) {
                this._editor.selectAll();
            }
        } else {
            this.doneEditing();
        }
    }

    doneEditing() {
        if (this._editor.html.trim() !== '') {
            this._comment.body = this._editor.html;
            api.saveAnnotation(this.id, this._editor.html, this._comment.json);
            sendGTMEvent(`${this._comment.isProjectDiscussion ? 'Discussion-Discussion' : 'Annotatate-Annotation'}-Edited`);
        }
        this._editMode = false;
    }

    cancelEdit() {
        this._editMode = false;
    }

    openNewReply() {
        this._discussion?.hideNewReply();
        this._showNewReply = true;
    }

    toggleEditMenu() {
        this.editMenu.toggle();
    }

    closeNewReply() {
        this._showNewReply = false;
        this.replies.forEach(r => r.closeNewReply());
    }

    submitReply() {
        const html = this._replyEditor.html.trim();
        if (html === '') {
            this.cancelReply();
            return;
        }
        this._showNewReply = false;
        api.sendReply(this.id, html);
    }

    cancelReply() {
        this._showNewReply = false;
    }

    toggleComplete() {
        this.setComment({ ...this.comment, isComplete: !this.isComplete });
        api.toggleCommentComplete(this.id, this.isComplete);
    }

    toggleInternal() {
        api.toggleCommentInternal(this.id, !this.isInternal);
        this.comment.isInternal = !this.isInternal;
    }

    highlight() {
        this._highlighted = true;
        this.frameElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    dropHighlight() {
        this._highlighted = false;
    }

    deleteComment() {
        this._discussion.deleteComment(this.id);
    }

    revealInViewer() {
        const root = this.findRoot();
        this.store.annotationManager.highlightAnnotation(root.id);
    }

    // #region Attachments

    downloadAttachment() {
        if (!this.comment.attachment) return;
        window.location = `/api/DownloadAttachment/${this.comment.attachment.uid}`;
    }

    initFileInput(element) {
        this._fileInput = element;
    }

    chooseAttachmentFile() {
        this._fileInput?.click();
    }

    viewAttachment() {
        this.store.proofX.openAttachmentViewer(this);
    }

    async uploadAttachment(file) {
        this.setUploadProgress(0);
        try {
            await api.uploadCommentAttachment(this.comment.commentUid, file, (value) => this.setUploadProgress(value));
        } catch (err) {
            console.error(err);
            const { strings } = this.store;
            this.setUploadError(strings.attachmentUploadError);
        }
        this.setUploadProgress(null);
    }

    deleteAttachment() {
        if (!this.attachment) return;
        const { strings, proofX } = this.store;
        proofX.showConfirm(
            strings.confirmDeletingAttachment,
            strings.deletingAttachmentPrompt,
            strings.delete,
            () => api.deleteCommentAttachment(this.id),
        );
    }

    setUploadProgress(value) {
        this._uploadProgress = value;
    }

    setUploadError(msg) {
        this._uploadError = msg;
    }
    // #endregion

    // #region Reactions

    toggleReaction(emoji) {
        const currentReactions = this.reactions;
        const proofX = this.store.proofX;
        const userReaction = currentReactions.find(r => r.ExecutorName === proofX.userName);
        let updatedReactions = [...currentReactions];
        if (userReaction) {
            if (userReaction.Emoji === emoji) {
                updatedReactions = currentReactions.filter(r => r.ExecutorName !== proofX.userName);
            } else {
                userReaction.Emoji = emoji;
            }
        } else {
            updatedReactions.push({ UserUid: proofX.environment.userUid, ExecutorName: proofX.userName, Emoji: emoji });
        }
        this._comment = {
            ...this._comment,
            reactionsJson: JSON.stringify(updatedReactions),
        };
        api.toggleReaction(this.id, emoji);
        sendGTMEvent('Reactions-Reaction-Toggled');
    }

    // #region Utility

    get _localeCode() { return useProofXStore?.getState().strings?.getLocaleCode() ?? 'en-US'; }

    findRoot() {
        return this.parent ? this.parent.findRoot() : this;
    }

    _formatDate(dateStr) {
        const date = new Date(dateStr.replace('(UTC)', 'GMT'));
        const localeCode = this._localeCode;
        return {
            date: new Intl.DateTimeFormat(localeCode, { year: 'numeric', month: 'short', day: 'numeric' }).format(date),
            time: new Intl.DateTimeFormat(localeCode, { hour: 'numeric', minute: 'numeric' }).format(date),
        };
    };

    // #endregion
}
