import React, { useRef } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../uicontrols/Button';
import { FlexRow } from '../../styles';

const Backdrop = styled(motion.div)`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100200;
    background-color: var(--modal-backdrop-color);
    overflow: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(10px);
`;

const Modal = styled(motion.div).attrs(() => ({
    className: 'glass-surface',
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 24px;
    box-shadow: 0 0 48px 0 var(--modal-shadow-color);
`;

export const Footer = styled(FlexRow)`
    justify-content: flex-end;
`;

export const FooterButton = styled(Button)`
    color: ${({ link }) => link ? 'var(--modal-footer-link-color)' : ''};
    padding: ${({ link }) => link ? 0 : ''};       
`;

export default observer(function ModalBase({ cubit, children }) {
    const modalRef = useRef();
    const backdropRef = useRef();

    const handleClick = (e) => {
        if (cubit.closeOnBackdropClick && e.target === backdropRef.current) {
            cubit.hide();
        }
    };

    return cubit && (
        <AnimatePresence>
            {cubit.isOpen && (
                <Backdrop
                    ref={backdropRef}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 0.5,
                    }}
                    onMouseDown={(e => handleClick(e))}
                >
                    <Modal
                        ref={modalRef}
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.5,
                            type: 'spring',
                            bounce: 0.35,
                        }}
                    >
                        {children}
                    </Modal>
                </Backdrop>
            )}
        </AnimatePresence>
    );
});
